import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import BaseApiGateway from './core/BaseApiGateway'
import BaseBackLinkingApiGateway from './core/BaseBackLinkingApiGateway'
import Clipboard from 'v-clipboard'
import BaseSimilarityMatchingApiGateway from './core/BaseSimilarityMatchingApiGateway'

Vue.config.productionTip = false

const token = localStorage.getItem('user-token')
if (token) {
  BaseApiGateway.defaults.headers.common['Authorization'] = 'Bearer ' + token
  BaseBackLinkingApiGateway.defaults.headers.common['Authorization'] =
    'Bearer ' + token
  BaseSimilarityMatchingApiGateway.defaults.headers.common['Authorization'] =
    'Bearer ' + token
}

Vue.use(Clipboard)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
