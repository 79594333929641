<template>
  <v-app>
    <v-app-bar app color="white">
      <v-toolbar-title>
        <router-link to="/dashboard/narratives">
          <v-img alt="Sentinel logo" src="./assets/sentinel.svg" />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="isAuthenticated">
        <v-tabs color="secondary">
          <v-tab v-if="hasRight('narratives-read')" to="/dashboard/narratives"
            >Disinformation articles
          </v-tab>
          <v-tab v-if="hasRight('campaigns-read')" to="/dashboard/campaigns"
            >Disinformation campaigns
          </v-tab>
          <v-tab v-if="hasRight('annotations-me-read')" to="/dashboard/history"
            >History
          </v-tab>
          <v-tab
            v-if="hasRight('annotations-organisation-read')"
            to="/dashboard/organisation/history"
            >Organisation History
          </v-tab>
        </v-tabs>
      </div>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" @click="$store.dispatch('logout')" icon>
        <v-icon>login</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <keep-alive :include="['DashboardNarratives', 'DashboardCampaigns']">
        <router-view></router-view>
      </keep-alive>
    </v-main>
    <v-footer app color="white">
      <span class="black--text">&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import BaseApiGateway from './core/BaseApiGateway'
import router from './router'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'App',

  created: function() {
    BaseApiGateway.interceptors.response.use(
      (response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.headers['user-rights']) {
            this.$store.dispatch(
              'saveAccessRights',
              response.headers['user-rights']
            )
          }
          return Promise.resolve(response)
        } else {
          return Promise.reject(response)
        }
      },
      (error) => {
        console.log(error)
        if (error.response && error.response.status === 401) {
          this.$store.dispatch('logout')
          router.push({
            path: '/',
          })
          if (error.response.status) {
            return Promise.reject(error.response)
          }
        }
        return Promise.reject(error)
      }
    )
    BaseApiGateway.interceptors.request.use(
      (config) => {
        //  Generate cancel token source
        let source = axios.CancelToken.source()

        // Set cancel token on this request
        config.cancelToken = source.token
        // Add to vuex to make cancellation available from anywhere
        this.$store.dispatch('addCancelTokens', source)

        return config
      },
      function(error) {
        return Promise.reject(error)
      }
    )
  },
  methods: {},
  computed: {
    ...mapGetters(['isAuthenticated', 'hasRight']),
  },
}
</script>
<style lang="scss">
$body-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
.v-application {
  font-family: $body-font-family, sans-serif !important;
  .body-1 {
    font-family: $body-font-family, sans-serif !important;
  }
}
.custom-tooltip {
  position: relative;
  color: red;
  cursor: pointer;
}

.custom-tooltip .tooltip-text {
  display: none;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  text-decoration: none !important;

  /* Position the tooltip */
  position: initial;
  z-index: 100;
  width: 50vw;
  bottom: 100%;
  left: 25%;
  right: 25%;
}
</style>
