<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md6>
      <v-card @keyup.enter="onLogin" class="elevation-12">
        <v-toolbar dark>
          <v-toolbar-title>Login Form</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" class="flex-fill">
            <v-text-field
              name="login"
              label="Login"
              placeholder="example@example.com"
              id="login"
              type="text"
              v-model="form.username"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              name="password"
              label="Password"
              placeholder="Password"
              id="password"
              type="password"
              v-model="form.password"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
          <v-container v-if="getAuthReason" style="height: 30px; color: red;">
            {{ getAuthReason }}
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="2" color="secondary" v-on:click="onLogin"
            >Login</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { DASHBOARD_ROUTE } from '../../dashboard/router'

export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      rules: {
        required: (value) => !!value || 'Required.',
      },
    }
  },
  computed: {
    ...mapGetters(['getAuthReason']),
  },
  methods: {
    onLogin() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.$store.dispatch('loginUser', this.form).then(() => {
        this.$router.push(DASHBOARD_ROUTE.name, () => {})
      })
    },
  },
}
</script>

<style module lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
</style>
