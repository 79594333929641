import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken2, // #E53935
        secondary: colors.shades.black, //
        accent: colors.indigo.base, // #3F51B5
      },
    },
  },
})

export default vuetify
