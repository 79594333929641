<template>
  <v-layout column class="ma-2">
    <v-combobox
      v-if="keywords && keywords.length > 0"
      v-model="term"
      :items="keywords"
      label="Search"
      multiple
      chips
    ></v-combobox>
    <v-container
      id="scroll-target"
      ref="campaignContainer"
      style="max-height: 75vh"
      class="overflow-y-auto"
    >
      <v-expansion-panels light v-scroll:#scroll-target="onScrollEnd">
        <DashboardCampaignListItem
          v-for="campaign in campaigns"
          :key="campaign.id"
          v-bind:id="campaign.id"
          v-bind:title="campaign.title"
          v-bind:keywords="campaign.keywords"
          v-bind:origins="campaign.origins"
          v-bind:article-count="campaign.articleCount"
        />
      </v-expansion-panels>
    </v-container>
  </v-layout>
</template>

<script>
import DashboardCampaignListItem from './DashboardCampaignListItem'

export default {
  name: 'DashboardCampaignList',
  components: { DashboardCampaignListItem },
  props: {
    campaigns: { type: Array, required: true },
    keywords: { type: Array },
    sortByImpact: { type: Boolean, required: true },
  },

  data: () => ({
    term: null,
  }),
  watch: {
    term: function(val) {
      this.$store
        .dispatch('dashboard/campaigns/applyCampaignFilter', {
          keywords: [...val],
        })
        .then(() =>
          this.$store.dispatch(
            'dashboard/campaigns/fetchCampaigns',
            this.sortByImpact
          )
        )
    },
  },

  computed: {},
  methods: {
    onScrollEnd(e) {
      if (
        e.target.scrollTop ===
        e.target.scrollHeight - e.target.clientHeight
      ) {
        if (!this.$store.state.dashboard.loadingCampaigns) {
          this.$store.dispatch(
            'dashboard/campaigns/fetchNextCampaigns',
            this.sortByImpact
          )
        }
      }
    },
    resetListScroll() {
      if (this.$refs.campaignContainer) {
        this.$refs.campaignContainer.scrollTop = 0
      }
    },
  },
}
</script>
