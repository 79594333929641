<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="origins && origins.length > 0 ? 6 : 12">
        <v-card outlined tile :loading="loadingCampaigns">
          <v-toolbar dark>
            <v-toolbar-title>Disinformation campaigns</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer />
            <v-switch v-model="sortByImpact">
              <template v-slot:prepend>
                <div
                  class="my-1"
                  style="cursor: pointer;"
                  @click="setSortByImpact(false)"
                >
                  Largest
                </div>
              </template>
              <template v-slot:append>
                <div
                  class="my-1"
                  style="cursor: pointer;"
                  @click="setSortByImpact(true)"
                >
                  Impact
                </div>
              </template>
            </v-switch>
          </v-toolbar>
          <DashboardCampaignList
            v-bind:keywords="keywords"
            v-bind:campaigns="campaigns"
            v-bind:sort-by-impact="sortByImpact"
          />
        </v-card>
      </v-col>
      <v-col v-if="origins && origins.length > 0" cols="5">
        <DashboardMap v-if="origins.length > 0" v-bind:origins="origins" />
      </v-col>
      <v-col v-if="keywords && keywords.length > 0" cols="1">
        <DashboardCampaignFilter v-bind:keywords="keywords" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCampaignList from '../components/Campaigns/DashboardCampaignList'
import DashboardCampaignFilter from '../components/Campaigns/DashboardCampaignFilter'
import DashboardMap from '../components/DashboardMap'
import { mapState } from 'vuex'

export default {
  name: 'DashboardCampaigns',
  components: {
    DashboardMap,
    DashboardCampaignFilter,
    DashboardCampaignList,
  },
  watch: {
    sortByImpact(newValue) {
      this.$store.dispatch('cancelPendingRequests').then(() => {
        this.$store.dispatch('dashboard/campaigns/clearCampaigns')
        this.$store
          .dispatch('dashboard/campaigns/fetchCampaigns', newValue)
          .then(() =>
            this.$store.dispatch('dashboard/campaigns/fetchCampaignsSummary')
          )
      })
    },
  },
  data() {
    return {
      sortByImpact: false,
    }
  },
  created() {
    this.$store.dispatch('cancelPendingRequests').then(() => {
      this.$store.dispatch('dashboard/campaigns/clearCampaigns')
      this.$store
        .dispatch('dashboard/campaigns/fetchCampaigns', this.sortByImpact)
        .then(() =>
          this.$store.dispatch('dashboard/campaigns/fetchCampaignsSummary')
        )
    })
  },
  methods: {
    setSortByImpact(value) {
      this.sortByImpact = value
    },
  },
  computed: mapState({
    campaigns: (state) => state.dashboard.campaigns.campaigns,
    keywords: (state) => state.dashboard.campaigns.keywords,
    origins: (state) => state.dashboard.campaigns.origins,
    languages: (state) => state.dashboard.campaigns.languages,
    loadingCampaigns: (state) => state.dashboard.campaigns.loadingCampaigns,
  }),
}
</script>

<style lang="scss" module></style>
