<template>
  <v-container fluid>
    <v-row>
      <v-spacer />
      <v-col cols="8">
        <v-card outlined tile>
          <v-toolbar dark>
            <v-toolbar-title>History</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-data-table
              :headers="headers"
              :items="annotations"
              :loading="loadingAnnotations"
              class="elevation-1"
            >
              <template v-slot:item.translated_title="{ item }">
                <a
                  style="color: black; text-decoration: none;"
                  :href="`/dashboard/article/${item.article_id}`"
                  target="_blank"
                  >{{ item.translated_title }}</a
                >
              </template>
              <template v-slot:item.feedback="{ item }">
                <span v-if="item.feedback" style="color: red;">YES</span>
                <span v-else style="color: green;">NO</span>
              </template>
              <template v-slot:item.created_date="{ item }">
                <span>{{ formatDateTime(item.created_date) }}</span>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import internationalisationMixin from '../../../mixins/internationalisationMixin'

export default {
  name: 'DashboardAnalystHistory',
  mixins: [internationalisationMixin],
  components: {},
  data() {
    return {
      headers: [
        {
          text: 'Title',
          align: 'start',
          value: 'translated_title',
        },
        { text: 'Disinfo', value: 'feedback' },
        { text: 'Date', value: 'created_date' },
      ],
      mediums: [],
    }
  },
  watch: {},
  created() {
    this.$store.dispatch('dashboard/setAnalystId', this.$route.params.annotator)
  },
  mounted() {
    this.$store.dispatch('dashboard/fetchArticleAnnotations')
  },

  computed: mapState({
    loadingAnnotations: (state) => state.dashboard.loadingAnnotations,
    annotations: (state) => state.dashboard.annotations,
    annotator: (state) => state.dashboard.annotator,
    username: (state) => state.auth.username,
  }),
}
</script>
