<template>
  <v-expansion-panel class="ma-2">
    <v-expansion-panel-header
      ><strong>{{ title }}</strong></v-expansion-panel-header
    >
    <v-expansion-panel-content>
      <v-col>
        <v-row class="my-1">
          <v-col cols="4">
            <v-row><h4>Disinfo Match</h4></v-row>
          </v-col>
          <v-col>
            <v-row>Very high</v-row>
          </v-col>
        </v-row>
        <v-row class="my-1">
          <v-col cols="4">
            <v-row><h4>Country</h4></v-row>
          </v-col>
          <v-col>
            <v-row>{{ country }}</v-row>
          </v-col>
        </v-row>
        <v-row class="my-1">
          <v-col cols="4">
            <v-row><h4>Language</h4></v-row>
          </v-col>
          <v-col>
            <v-row>{{ language }}</v-row>
          </v-col>
        </v-row>
        <v-row class="my-1">
          <v-col>
            <v-row class="my-1">
              <h4>Text preview</h4>
            </v-row>
            <v-row class="my-1">
              {{ textPreview }}
            </v-row>
          </v-col>
        </v-row>

        <v-row class="my-1">
          <v-btn
            elevation="2"
            color="secondary"
            class="float-right"
            :to="narrativeRoute"
          >
            Open
          </v-btn>
        </v-row>
      </v-col>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { ARTICLE_ROUTE } from '../../router'

export default {
  name: 'DashboardNarrativeListItem',
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
  },
  computed: {
    narrativeRoute: function() {
      return {
        name: ARTICLE_ROUTE.name,
        params: { id: this.id },
      }
    },
    textPreview: function() {
      if (this.text) {
        return this.text.substr(0, 200) + '...'
      }
      return ''
    },
  },
}
</script>

<style scoped></style>
