var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"prepend-icon":"search","name":"Search","label":"Search text","single-line":"","color":"deep-purple accent-4"},model:{value:(_vm.appliedFilter.term),callback:function ($$v) {_vm.$set(_vm.appliedFilter, "term", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"appliedFilter.term"}}),_c('v-select',{ref:"languageSelect",attrs:{"multiple":"","chips":"","clearable":"","item-color":"black","items":_vm.languages,"label":"Select language","dense":"","prepend-icon":"mic","color":"primary"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',{attrs:{"input-value":selected,"color":"primary"}},[_c('strong',[_vm._v(_vm._s(item))])])]}},{key:"append-outer",fn:function(){return [_c('v-divider',{staticClass:"mt-2"}),_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.closeLanguageSelect}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-btn',{attrs:{"elevation":"2","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.originsSelectActionTitle)+" ")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.appliedFilter.language),callback:function ($$v) {_vm.$set(_vm.appliedFilter, "language", $$v)},expression:"appliedFilter.language"}}),_c('v-select',{ref:"countrySelect",attrs:{"multiple":"","chips":"","clearable":"","deletable-chips":"","item-color":"secondary","hint":"Select multiple origins","persistent-hint":"","items":_vm.origins,"label":"Origin","color":"secondary","dense":"","prepend-icon":"map"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',{attrs:{"input-value":selected,"color":"secondary"}},[_c('strong',[_vm._v(_vm._s(item))])])]}},{key:"append-outer",fn:function(){return [_c('v-divider',{staticClass:"mt-2"}),_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.closeCountrySelect}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-btn',{attrs:{"elevation":"2","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.originsSelectActionTitle)+" ")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.appliedFilter.country),callback:function ($$v) {_vm.$set(_vm.appliedFilter, "country", $$v)},expression:"appliedFilter.country"}}),_c('v-menu',{ref:"datePickerMenu",attrs:{"close-on-content-click":false,"return-value":_vm.appliedFilter.dates,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.appliedFilter, "dates", $event)},"update:return-value":function($event){return _vm.$set(_vm.appliedFilter, "dates", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Article publish date range","prepend-icon":"date_range","color":"accent-4","readonly":""},model:{value:(_vm.appliedFilter.dates),callback:function ($$v) {_vm.$set(_vm.appliedFilter, "dates", $$v)},expression:"appliedFilter.dates"}},on))]}}]),model:{value:(_vm.datePickerMenu),callback:function ($$v) {_vm.datePickerMenu=$$v},expression:"datePickerMenu"}},[_c('v-date-picker',{attrs:{"dark":"","min":_vm.minDate,"max":_vm.maxDate,"range":"","no-title":"","scrollable":"","first-day-of-week":"1"},model:{value:(_vm.appliedFilter.dates),callback:function ($$v) {_vm.$set(_vm.appliedFilter, "dates", $$v)},expression:"appliedFilter.dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.datePickerMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.appliedFilter.dates = []}}},[_vm._v("Clear")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$refs.datePickerMenu.save(_vm.appliedFilter.dates)}}},[_vm._v(" OK ")])],1)],1),_c('div',{staticClass:"py-2"},[_c('v-btn',{staticClass:"mx-1",attrs:{"elevation":"2","color":"secondary"},on:{"click":_vm.applyFilter}},[_vm._v("Apply")]),_c('v-btn',{staticClass:"mx-1",attrs:{"elevation":"2","color":"secondary"},on:{"click":_vm.toggleFilter}},[_vm._v("Close")]),_c('v-btn',{staticClass:"mx-1",attrs:{"elevation":"2"},on:{"click":_vm.clearFilter}},[_vm._v("Clear")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }