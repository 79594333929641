<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col>
        <v-layout>
          <Login class="mt-5" />
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Login from '../components/Login.vue'

export default {
  name: 'LandingHome',
  components: {
    Login,
  },
}
</script>
