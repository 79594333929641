<template>
  <v-container fluid>
    <v-layout column>
      <v-row class="mt-3 mb-5 ml-1">
        <v-btn color="black" icon @click="goBack">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <h2>{{ campaign.title }}</h2>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card
            outlined
            tile
            :loading="loadingCampaign || loadingCampaignSummary"
          >
            <v-toolbar dark>
              <v-toolbar-title>Details</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <!--<v-row>
                <v-col>
                  <v-row class="title">Keywords</v-row>
                  <v-row>
                    <v-chip-group column>
                      <v-chip
                        v-for="(keyword, index) in campaignSummary.keywords"
                        :key="index"
                        pill
                      >
                        {{ keyword }}
                      </v-chip>
                    </v-chip-group>
                  </v-row>
                </v-col>
              </v-row>
              -->
              <v-row class="ma-3">
                <v-col>
                  <v-row class="title  mb-2"
                    >Disinformation article matches
                  </v-row>
                  <v-row class="subtitle-1">
                    {{ formatNumber(campaignSummary.articleCount) }}
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="ma-3">
                <v-col>
                  <v-row class="title mb-2">Total interactions</v-row>
                  <v-row class="subtitle-1">
                    {{ formatNumber(campaign.interactions) }}
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <DashboardCampaignImpactTable v-bind:campaign-id="$route.params.id" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card outlined tile :loading="loadingArticles">
            <v-toolbar dark>
              <v-toolbar-title>
                Disinformation articles
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title class="text-body-2">
                sorted by impact
              </v-toolbar-title>
            </v-toolbar>
            <v-card>
              <v-card-text>
                <DashboardArticleFilter
                  class="my-1 mr-1"
                  v-bind:origins="campaignSummary.origins"
                  v-bind:languages="campaignSummary.languages"
                />
              </v-card-text>
            </v-card>

            <DashboardArticleList v-bind:campaign-id="$route.params.id" />
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card v-if="campaign" outlined tile>
            <DashboardCampaignChart />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6"></v-col>
        <v-col cols="6"></v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import DashboardArticleList from '../components/Campaign/DashboardArticleList.vue'
import DashboardArticleFilter from '../components/Campaign/Filter/DashboardArticleFilter'
import DashboardCampaignImpactTable from '../components/Campaign/DashboardCampaignImpactTable'
import { mapState } from 'vuex'
import DashboardCampaignChart from '../components/Campaign/DashboardCampaignChart'
import internationalisationMixin from '../../../mixins/internationalisationMixin'

export default {
  name: 'DashboardCampaign',
  mixins: [internationalisationMixin],
  components: {
    DashboardCampaignChart,
    DashboardCampaignImpactTable,
    DashboardArticleFilter,
    DashboardArticleList,
  },
  created() {
    this.$store.dispatch(
      'dashboard/campaign/fetchCampaign',
      this.$route.params.id
    )
  },
  computed: mapState({
    origins: (state) => state.dashboard.campaign.origins,
    languages: (state) => state.dashboard.campaign.languages,
    campaign: (state) => state.dashboard.campaign.campaign,
    campaignSummary: (state) => state.dashboard.campaign.campaignSummary,
    loadingCampaignSummary: (state) =>
      state.dashboard.campaign.loadingCampaignSummary,
    loadingCampaign: (state) => state.dashboard.campaign.loadingCampaign,
    loadingArticles: (state) => state.dashboard.campaign.loadingArticles,
  }),
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
  },
}
</script>
