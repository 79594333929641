<template>
  <v-card outlined tile :loading="loadingCampaignInteractions">
    <v-toolbar dark>
      <v-toolbar-title>Impact</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="campaignInteractions"
        class="elevation-1"
      >
        <template v-slot:item.interactions="{ item }">
          <span>{{ formatNumber(item.interactions) }}</span>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import internationalisationMixin from '../../../../mixins/internationalisationMixin'

export default {
  name: 'DashboardCampaignImpactTable',
  mixins: [internationalisationMixin],
  created() {
    this.$store.dispatch('dashboard/campaign/fetchCampaignInteractions')
  },
  data() {
    return {
      headers: [
        {
          text: 'Platform',
          align: 'start',
          sortable: false,
          value: 'platform',
        },
        { text: 'Interactions', value: 'interactions' },
      ],
    }
  },
  computed: mapState({
    campaignInteractions: (state) =>
      state.dashboard.campaign.campaignInteractions,
    loadingCampaignInteractions: (state) =>
      state.dashboard.campaign.loadingCampaignInteractions,
  }),
}
</script>
