<template>
  <v-card outlined :loading="isLoading">
    <v-card-title>Impact</v-card-title>
    <v-card-subtitle>Interactions on different platforms</v-card-subtitle>
    <v-card-text class="pl-7" v-if="impact">
      <v-row class="my-1">
        <strong
          style="content: url('https://api.iconify.design/mdi-facebook.svg?height=24');
vertical-align: -0.125em;"
          >Facebook:
        </strong>
        <span class="pl-2" v-if="impact.mediums.facebook">
          {{ formatNumber(impact.mediums.facebook.interactions) }}
        </span>
        <span class="pl-2" v-else>0</span>
      </v-row>
      <v-row class="my-1">
        <strong
          style="content: url('https://api.iconify.design/ant-design:twitter-circle-filled.svg?height=24');
vertical-align: -0.125em;"
          >Twitter:</strong
        >
        <span class="pl-2" v-if="impact.mediums.twitter">
          {{ formatNumber(impact.mediums.twitter.interactions) }}
        </span>
        <span class="pl-2" v-else>0</span>
      </v-row>
      <v-row class="my-1">
        <strong
          style="content: url('https://api.iconify.design/ant-design:reddit-circle-filled.svg?height=24');
vertical-align: -0.125em;"
          >Reddit:</strong
        >
        <span class="pl-2" v-if="impact.mediums.reddit">
          {{ formatNumber(impact.mediums.reddit.interactions) }}
        </span>
        <span class="pl-2" v-else>0</span>
      </v-row>
      <v-row class="my-1">
        <strong
          style="content: url('https://api.iconify.design/ant-design:instagram-filled.svg?height=24');
vertical-align: -0.125em;"
          >Instagram:</strong
        >
        <span class="pl-2" v-if="impact.mediums.instagram">
          {{ formatNumber(impact.mediums.instagram.interactions) }}
        </span>
        <span class="pl-2" v-else>0</span>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DashboardService from '../../service/DashboardService'
import internationalisationMixin from '../../../../mixins/internationalisationMixin'

export default {
  name: 'DashboardArticleImpact',
  mixins: [internationalisationMixin],
  props: {
    articleId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      impact: null,
      isLoading: true,
    }
  },
  created() {
    this.isLoading = true
    this.fetchInterActions().then(() => {
      this.isLoading = false
    })
  },
  methods: {
    async fetchInterActions() {
      const { data } = await DashboardService.findInteractionsForArticle(
        this.articleId
      )
      this.impact = data
    },
  },
}
</script>
