import narratives from './dashboard/dashboard.narratives.store'
import campaigns from './dashboard/dashboard.campaigns.store'
import campaign from './dashboard/dashboard.campaign.store'
import DashboardService from '../../modules/dashboard/service/DashboardService'
import BackLinkingService from '../../modules/dashboard/service/BackLinkingService'
import SimilarityMatchingService from '../../modules/dashboard/service/SimilarityMatchingService'

export const DEFAULT_CAMPAIGNS_QUERY = {
  keywords: [],
  country: [],
  category: [],
}
export const DEFAULT_ARTICLE_FILTER = {
  country: [],
  language: [],
  dates: [],
  minDate: null,
  maxDate: null,
}
export const DEFAULT_NARRATIVE_QUERY = {
  analystLanguage: null,
  country: [],
  language: [],
  dates: [],
}

export const DEFAULT_CAMPAIGN_INTERACTIONS_MEDIUMS = [
  {
    platform: 'Facebook',
    interactions: null,
  },
  {
    platform: 'Twitter',
    interactions: null,
  },
  {
    platform: 'Reddit',
    interactions: null,
  },
  {
    platform: 'Instagram',
    interactions: null,
  },
]

const initialState = () => ({
  loadingArticle: false,
  loadingArticleBackLinking: false,
  article: {},
  backLinking: {},
  articleFeedback: null,
  loadingArticleFeedback: false,
  organisation: { annotations: [], analysts: [], analystOverview: null },
  analyst: null,
  annotations: [],
  loadingAnnotations: false,
  loadingAnalysts: false,
  loadingAnalystOverview: false,
  similarityMatch: null,
  loadingArticleSimilarityMatch: false,
})

const state = () => initialState()

const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  ARTICLE_LOADING_TOGGLED(state, value) {
    state.loadingArticle = value
  },
  ARTICLE_BACK_LINKING_LOADING_TOGGLED(state, value) {
    state.loadingArticleBackLinking = value
  },
  ARTICLE_SIMILARITY_MATCHING_LOADING_TOGGLED(state, value) {
    state.loadingArticleSimilarityMatch = value
  },
  ARTICLE_SIMILARITY_MATCH_SET(state, similarityMatch) {
    state.similarityMatch = similarityMatch
  },
  ARTICLE_SET(state, article) {
    state.article = article
    state.articleFeedback = null
    state.articleFeedbackDate = null
  },
  ARTICLE_BACK_LINKING_SET(state, backLinking) {
    state.backLinking = backLinking
  },
  ARTICLE_ANNOTATION_LOADING_TOGGLED(state, value) {
    state.loadingArticleFeedback = value
  },
  ARTICLE_ANNOTATION_SET(state, data) {
    if (data) {
      state.articleFeedback = data.feedback
      state.articleFeedbackDate = data.created_date
    }
  },
  ARTICLE_ANNOTATED(state, data) {
    state.articleFeedback = data.feedback
  },
  ANNOTATIONS_LOADING_TOGGLED(state, value) {
    state.loadingAnnotations = value
  },
  ANNOTATIONS_SET(state, annotations) {
    state.annotations = annotations
  },
  ANALYST_SET(state, analyst) {
    state.analyst = analyst
  },
  ORGANISATION_ANNOTATIONS_SET(state, annotations) {
    state.organisation.annotations = annotations
  },
  ANALYSTS_LOADING_TOGGLED(state, value) {
    state.loadingAnalysts = value
  },
  ORGANISATION_ANALYSTS_SET(state, analysts) {
    state.organisation.analysts = analysts
  },
  ANALYSTS_OVERVIEW_LOADING_TOGGLED(state, value) {
    state.loadingAnalystOverview = value
  },
  ANALYST_OVERVIEW_SET(state, overview) {
    state.organisation.analystOverview = overview
  },
}

const actions = {
  fetchArticle({ commit, dispatch }, query) {
    return new Promise((resolve) => {
      commit('ARTICLE_LOADING_TOGGLED', true)
      commit('ARTICLE_BACK_LINKING_SET', {})
      DashboardService.findArticle(query.id)
        .then((response) => {
          commit('ARTICLE_SET', response.data)
          commit('ARTICLE_LOADING_TOGGLED', false)
          return response
        })
        .then((response) => {
          dispatch('fetchArticleBackLinking', query.id)
          if (response && response.data.classification_result) {
            commit(
              'ARTICLE_SIMILARITY_MATCH_SET',
              response.data.classification_result
            )
          } else {
            dispatch('fetchArticleSimilarityMatch', query.id)
          }
          dispatch('fetchArticleAnnotation', query)
        })
      resolve()
    })
  },
  fetchArticleBackLinking({ commit }, article_id) {
    return new Promise((resolve) => {
      commit('ARTICLE_BACK_LINKING_LOADING_TOGGLED', true)
      BackLinkingService.find_matches(article_id).then((response) => {
        commit('ARTICLE_BACK_LINKING_SET', response.data)
        commit('ARTICLE_BACK_LINKING_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },
  fetchArticleSimilarityMatch({ commit }, article_id) {
    return new Promise((resolve) => {
      commit('ARTICLE_SIMILARITY_MATCHING_LOADING_TOGGLED', true)
      SimilarityMatchingService.fetch_similarity_match(article_id).then(
        (response) => {
          commit('ARTICLE_SIMILARITY_MATCH_SET', response.data)
          commit('ARTICLE_SIMILARITY_MATCHING_LOADING_TOGGLED', false)
        }
      )
      resolve()
    })
  },
  annotateArticle({ commit }, payload) {
    return new Promise((resolve) => {
      DashboardService.annotateArticle(payload).then(() => {
        commit('ARTICLE_ANNOTATED', payload)
      })
      resolve()
    })
  },
  fetchArticleAnnotation({ commit }, query) {
    return new Promise((resolve) => {
      commit('ARTICLE_ANNOTATION_LOADING_TOGGLED', true)
      DashboardService.findArticleAnnotation(query.id).then((response) => {
        commit('ARTICLE_ANNOTATION_SET', response.data)
        commit('ARTICLE_ANNOTATION_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },
  fetchArticleAnnotations({ commit }, analyst) {
    return new Promise((resolve) => {
      commit('ANNOTATIONS_LOADING_TOGGLED', true)
      DashboardService.findArticleAnnotations(analyst).then((response) => {
        commit('ANNOTATIONS_SET', response.data)
        commit('ANNOTATIONS_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },

  setAnalystId({ commit }, analyst) {
    return new Promise((resolve) => {
      commit('ANALYST_SET', analyst)
      resolve(analyst)
    })
  },

  fetchOrganisationAnnotations({ commit }) {
    return new Promise((resolve) => {
      commit('ANNOTATIONS_LOADING_TOGGLED', true)
      DashboardService.findOrganisationAnnotations().then((response) => {
        commit('ORGANISATION_ANNOTATIONS_SET', response.data)
        commit('ANNOTATIONS_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },
  fetchOrganisationAnalysts({ commit }) {
    return new Promise((resolve) => {
      commit('ANALYSTS_LOADING_TOGGLED', true)
      DashboardService.findOrganisationAnalysts().then((response) => {
        commit('ORGANISATION_ANALYSTS_SET', response.data)
        commit('ANALYSTS_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },
  fetchAnalystOverview({ commit }, username) {
    return new Promise((resolve) => {
      commit('ANALYSTS_OVERVIEW_LOADING_TOGGLED', true)
      DashboardService.fetchAnalystOverview(username).then((response) => {
        commit('ANALYST_OVERVIEW_SET', response.data)
        commit('ANALYSTS_OVERVIEW_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },
  updateMap({ commit }, origins) {
    return new Promise((resolve) => {
      commit('MAP_ORIGINS_SET', origins)
      commit('MAP_UPDATED')
      resolve()
    })
  },
  resetMapOrigins({ commit }) {
    return new Promise((resolve) => {
      commit('MAP_ORIGINS_RESET')
      commit('MAP_UPDATED')
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    narratives,
    campaigns,
    campaign,
  },
}
