export default {
  methods: {
    formatDateTime(dateString) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }

      return dateString
        ? new Intl.DateTimeFormat('en-GB', options).format(
            new Date(dateString + 'Z')
          )
        : ''
    },
    formatDate(dateString) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }

      return dateString
        ? new Intl.DateTimeFormat('en-GB', options).format(
            new Date(dateString + 'Z')
          )
        : ''
    },

    formatNumber(numericValue) {
      const value = numericValue ? numericValue : 0
      return new Intl.NumberFormat('en-GB').format(value).replace(',', ' ')
    },
  },
}
