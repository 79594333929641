<template>
  <v-row class="my-3" align="center" justify="center">
    <line-chart
      :styles="myStyles"
      :options="options"
      :chartData="dataCollection"
    ></line-chart>
  </v-row>
</template>

<script>
import LineChart from '../LineChart.js'
import DashboardService from '../../service/DashboardService'
import internationalisationMixin from '../../../../mixins/internationalisationMixin'
import { mapGetters } from 'vuex'

const colorMap = {
  facebook: '#4267B2',
  twitter: '#1DA1F2',
  reddit: '#FF4301',
  instagram: '#C13584',
}
export default {
  name: 'DashboardCampaignChart',
  mixins: [internationalisationMixin],
  components: {
    LineChart,
  },
  props: {
    chartTitle: {
      type: String,
      default: 'Impact',
    },
    campaignId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      dataCollection: null,
      options: null,
      myStyles: {
        width: `70%`,
      },
    }
  },
  mounted() {
    this.fillData()
  },
  methods: {
    fillData() {
      DashboardService.findInteractionsChartDataForCampaign(
        this.getCampaignId
      ).then((response) => {
        Object.keys(response.data.mediums).map((a) => {
          if (response.data.mediums[a].length === 0)
            delete response.data.mediums[a]
        })
        let result = Object.keys(response.data.mediums).map((a) => {
          if (response.data.mediums[a].length === 0) {
            delete response.data.mediums[a]
            return
          }
          return {
            label: a,
            backgroundColor: colorMap[a],
            borderColor: colorMap[a],
            fill: false,
            data: [...response.data.mediums[a]],
            cubicInterpolationMode: 'monotone',
          }
        })
        this.dataCollection = {
          labels: [...response.data.options.labels],
          datasets: result,
        }
        this.options = {
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (tooltipItem, data) => {
                return `${
                  data.datasets[tooltipItem.datasetIndex].label
                } - ${this.formatNumber(
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ]
                )}`
              },
            },
          },
          responsive: true,
          scales: {
            xAxes: [
              {
                type: 'time',
                ticks: { beginAtZero: true },
                scaleLabel: {
                  display: true,
                  labelString: 'Date',
                },
                time: {
                  tooltipFormat: 'MMM D',
                  displayFormats: {
                    hour: 'MMM D',
                  },
                },
              },
            ],
            yAxes: [
              {
                type: 'logarithmic',
                position: 'left',
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  // max: 100000,
                  callback: function(value) {
                    if (value === 100000) return '100K'
                    if (value === 10000) return '10K'
                    if (value === 1000) return '1K'
                    if (value === 100) return '100'
                    if (value === 10) return '10'
                    if (value === 0) return '0'
                    return null
                  },
                },
              },
            ],
          },
        }
      })
    },
  },
  computed: {
    ...mapGetters('dashboard/campaign', ['getCampaignId']),
  },
}
</script>

<style></style>
