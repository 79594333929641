import DashboardCampaign from '@/modules/dashboard/views/DashboardCampaign'
import DashboardNarratives from '@/modules/dashboard/views/DashboardNarratives'
import DashboardCampaigns from '@/modules/dashboard/views/DashboardCampaigns'
import DashboardArticle from '@/modules/dashboard/views/DashboardArticle'
import DashboardAnalystHistory from '@/modules/dashboard/views/DashboardAnalystHistory'
import DashboardOrganisationHistory from '@/modules/dashboard/views/DashboardOrganisationHistory'

export const DASHBOARD_ROUTE = { name: 'dashboard' }
export const CAMPAIGNS_ROUTE = { name: 'campaigns' }
export const HISTORY_ROUTE = { name: 'history' }
export const ANALYST_HISTORY_ROUTE = { name: 'analyst_history' }
export const ORGANISATION_HISTORY_ROUTE = {
  name: 'organisation_history',
}
export const CAMPAIGN_ROUTE = { name: 'campaign' }
export const NARRATIVE_ROUTE = { name: 'narratives' }
export const CAMPAIGN_ARTICLE_ROUTE = { name: 'campaign_article' }
export const ARTICLE_ROUTE = { name: 'article' }

const DASHBOARD_ROUTES = [
  {
    path: '/dashboard/campaigns/:id',
    name: CAMPAIGN_ROUTE.name,
    component: DashboardCampaign,
  },
  {
    path: '/dashboard',
    name: DASHBOARD_ROUTE.name,
    component: DashboardNarratives,
  },
  {
    path: '/dashboard/narratives',
    name: NARRATIVE_ROUTE.name,
    component: DashboardNarratives,
  },
  {
    path: '/dashboard/campaigns',
    name: CAMPAIGNS_ROUTE.name,
    component: DashboardCampaigns,
  },
  {
    path: '/dashboard/campaigns/:campaignId/article/:id',
    name: CAMPAIGN_ARTICLE_ROUTE.name,
    component: DashboardArticle,
  },
  {
    path: '/dashboard/article/:id',
    name: ARTICLE_ROUTE.name,
    component: DashboardArticle,
  },
  {
    path: '/dashboard/history',
    name: HISTORY_ROUTE.name,
    component: DashboardAnalystHistory,
  },
  {
    path: '/dashboard/history/:analyst',
    name: ANALYST_HISTORY_ROUTE.name,
    component: DashboardAnalystHistory,
  },
  {
    path: '/dashboard/organisation/history',
    name: ORGANISATION_HISTORY_ROUTE.name,
    component: DashboardOrganisationHistory,
  },
]

export default DASHBOARD_ROUTES
