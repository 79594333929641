import LoginService from '../../modules/landing/service/LoginService'
import BaseApiGateway from '../../core/BaseApiGateway'
import router from '../../router'
import decode from 'jwt-decode'

const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

const DEFAULT_AUTH_STATE = {
  reason: null,
  accessToken: null,
  rights: [],
}

const initialState = () => ({
  auth: DEFAULT_AUTH_STATE,
})

const state = () => initialState()

const mutations = {
  SET_ACCESS_RIGHTS: (state, rights) => {
    state.auth.rights = rights
  },
  RESET_AUTH: (state) => {
    state.auth = DEFAULT_AUTH_STATE
  },
  USER_LOGOUT: (state, response) => {
    state.auth.reason = response
    state.auth.accessToken = null
  },
  UPDATE_ACCESS_TOKEN: (state, accessToken) => {
    state.auth.accessToken = accessToken
  },
  SET_USERNAME(state, username) {
    state.username = username
  },
}

const actions = {
  loginUser({ commit }, form) {
    return new Promise((resolve, reject) => {
      commit('RESET_AUTH')
      LoginService.login(form)
        .then((response) => {
          if (response.data.status === 'NOK') {
            commit('USER_LOGOUT', response.data.reason)
            localStorage.removeItem('user-token')
            delete BaseApiGateway.defaults.headers.common['Authorization']
            reject(response.data.reason)
          } else if (response.data.status === 'OK') {
            commit('UPDATE_ACCESS_TOKEN', response.data.token)
            localStorage.setItem('user-token', response.data.token)
            BaseApiGateway.defaults.headers.common['Authorization'] =
              'Bearer ' + response.data.token
            resolve()
          }
        })
        .catch(() => {
          reject('failed')
        })
    })
  },
  fetchAccessToken({ commit, state }) {
    return new Promise((resolve) => {
      commit('RESET_AUTH')
      const userToken = localStorage.getItem('user-token')
      commit('UPDATE_ACCESS_TOKEN', userToken)
      const jwtToken = parseJwt(userToken)
      commit('SET_USERNAME', jwtToken.username)
      resolve(state.auth.accessToken)
    })
  },
  saveAccessRights: ({ commit }, rights) => {
    return new Promise((resolve) => {
      commit('SET_ACCESS_RIGHTS', JSON.parse(rights))
      resolve()
    })
  },
  logout: ({ commit }) => {
    return new Promise((resolve) => {
      commit('RESET_AUTH')
      commit('USER_LOGOUT', 'Logged out')
      commit('dashboard/RESET')
      localStorage.removeItem('user-token')
      delete BaseApiGateway.defaults.headers.common['Authorization']
      router.push('/')
      resolve()
    })
  },
}
const getters = {
  isAuthenticated: (state) => !!state.auth.accessToken,
  isAuthenticationExpired: (state) => {
    let token = decode(state.auth.accessToken)
    if (!token.exp) {
      return true
    }
    let date = new Date(0)
    date.setUTCSeconds(token.exp)
    return date < new Date()
  },
  getAuthReason: (state) => state.auth.reason,
  hasRight: (state) => (right) => state.auth.rights.includes(right),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
