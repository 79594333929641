<template>
  <v-card outlined tile :loading="isLoading">
    <v-row class="my-3" v-if="noChartData">
      <v-spacer />
      <v-col cols="6">At the moment there is no impact</v-col>
      <v-spacer />
    </v-row>
    <v-row class="my-3" v-else align="center" justify="center">
      <line-chart
        v-if="articleId"
        :options="options"
        :chartData="dataCollection"
        class="m-3"
        :styles="myStyles"
      ></line-chart>
    </v-row>
  </v-card>
</template>

<script>
import LineChart from '../LineChart.js'
import DashboardService from '../../service/DashboardService'
import internationalisationMixin from '../../../../mixins/internationalisationMixin'

const colorMap = {
  facebook: '#4267B2',
  twitter: '#1DA1F2',
  reddit: '#FF4301',
  instagram: '#C13584',
}

const zeroDataAnnotation = {
  annotations: [
    {
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value: 0,
      borderColor: '#4267B2',
      borderWidth: 5,
      label: {
        enabled: false,
        content: 'Test label',
      },
    },
  ],
}
export default {
  name: 'DashboardArticleChart',
  mixins: [internationalisationMixin],
  components: {
    LineChart,
  },
  props: {
    articleId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      dataCollection: null,
      options: null,
      isLoading: false,
      noChartData: true,
      myStyles: {
        width: `60%`,
      },
    }
  },
  mounted() {
    this.fillData()
  },
  methods: {
    fillData() {
      this.isLoading = true
      DashboardService.findInteractionsChartDataForArticle(this.articleId)
        .then((response) => {
          this.isLoading = false
          let result = Object.keys(response.data.mediums).map((a) => {
            return {
              label: a,
              backgroundColor: colorMap[a],
              borderColor: colorMap[a],
              fill: false,
              data: [...response.data.mediums[a]],
              cubicInterpolationMode: 'monotone',
            }
          })
          this.dataCollection = {
            labels: [...response.data.options.labels],
            datasets: result,
          }
          this.noChartData = false
          this.options = {
            tooltips: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: (tooltipItem, data) => {
                  return `${
                    data.datasets[tooltipItem.datasetIndex].label
                  } - ${this.formatNumber(
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ].y
                  )}`
                },
              },
            },
            responsive: true,
            scales: {
              xAxes: [
                {
                  type: 'time',
                  scaleLabel: {
                    display: true,
                    labelString: 'Date',
                  },
                  time: {
                    tooltipFormat: 'MMM D',
                    displayFormats: {
                      hour: 'MMM D',
                    },
                  },
                },
              ],
              yAxes: [
                {
                  type: 'logarithmic',
                  position: 'left',
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    // max: 100000,
                    callback: function(value) {
                      if (value === 100000) return '100K'
                      if (value === 10000) return '10K'
                      if (value === 1000) return '1K'
                      if (value === 100) return '100'
                      if (value === 10) return '10'
                      if (value === 0) return '0'
                      return null
                    },
                  },
                },
              ],
            },
          }
          if (
            response.data &&
            response.data.mediums &&
            Object.keys(response.data.mediums).length === 0
          ) {
            this.options['annotation'] = zeroDataAnnotation
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style>
.small {
  margin: auto;
}
</style>
