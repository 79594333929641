var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Users")])],1),_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.analystHeaders,"items":_vm.analysts,"loading":_vm.loadingAnalysts,"page":_vm.pagination.page},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"pagination":_vm.updatePage},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.showAnalyst(item.username)}}},[_vm._v(" "+_vm._s(item.username)+" ")])]}},{key:"item.created_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.created_date)))])]}}])})],1)],1),_c('v-spacer'),(_vm.analystOverview)?_c('v-card',{attrs:{"loading":_vm.loadingAnalysts,"outlined":"","tile":""}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(" Viewing details for "+_vm._s(_vm.analystOverview.username)+" ")])],1),_c('v-container',[_c('v-row',[_c('v-col',[_vm._v("Marked as disinformation")]),_c('v-col',[_vm._v(_vm._s(_vm.analystOverview.debunks))])],1),_c('v-row',[_c('v-col',[_vm._v("Marked as not disinformation")]),_c('v-col',[_vm._v(_vm._s(_vm.analystOverview.truths))])],1)],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("History")])],1),(!_vm.filteredAnnotations)?_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.historyHeaders,"items":_vm.annotations,"loading":_vm.loadingAnnotations,"group-by":"username","show-group-by":"","page":_vm.pagination.page},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"pagination":_vm.updatePage},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"3"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'remove' : 'add')+" ")]),_vm._v(" User: "+_vm._s(items[0].username)+" ")],1)]}},{key:"item.feedback",fn:function(ref){
var item = ref.item;
return [(item.feedback)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("YES")]):_c('span',{staticStyle:{"color":"green"}},[_vm._v("NO")])]}},{key:"item.translated_title",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"color":"black","text-decoration":"none"},attrs:{"href":("/dashboard/article/" + (item.article_id)),"target":"_blank"}},[_vm._v(_vm._s(item.translated_title))])]}},{key:"item.created_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.created_date)))])]}}],null,false,1100710185)})],1):_vm._e(),(_vm.filteredAnnotations)?_c('v-container',[_c('a',{staticStyle:{"color":"black","text-decoration":"none"},on:{"click":_vm.showAllAnnotations}},[_vm._v(" Show all ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.historyHeaders,"items":_vm.filteredAnnotations,"loading":_vm.loadingAnnotations,"group-by":"username","show-group-by":"","page":_vm.pagination.page},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"pagination":_vm.updatePage},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"3"}},[_c('v-icon',{on:{"click":function($event){return _vm.remove(items[0].username, toggle, isOpen)}}},[_vm._v(_vm._s(isOpen ? 'remove' : 'add')+" ")]),_vm._v(" User: "+_vm._s(items[0].username)+" ")],1)]}},{key:"item.feedback",fn:function(ref){
var item = ref.item;
return [(item.feedback)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("YES")]):_c('span',{staticStyle:{"color":"green"}},[_vm._v("NO")])]}},{key:"item.translated_title",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"color":"black","text-decoration":"none"},attrs:{"href":("/dashboard/article/" + (item.article_id)),"target":"_blank"}},[_vm._v(_vm._s(item.translated_title))])]}},{key:"item.created_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.created_date)))])]}}],null,false,2561959535)})],1):_vm._e()],1)],1),_c('v-spacer'),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }