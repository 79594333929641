import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingHome from '../modules/landing/views/LandingHome.vue'
import DASHBOARD_ROUTES, { DASHBOARD_ROUTE } from '../modules/dashboard/router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LandingHome,
    meta: {
      allowAnonymous: true,
    },
  },
  ...DASHBOARD_ROUTES,
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  store.dispatch('cancelPendingRequests')
  store.dispatch('fetchAccessToken').then((token) => {
    if (token && store.getters.isAuthenticationExpired) {
      store.dispatch('logout').catch(() => {
        console.timeLog('Failed to log out')
      })
    }
  })
  if (to.name === 'home' && store.getters.isAuthenticated) {
    next({ name: DASHBOARD_ROUTE.name })
  } else if (!to.meta.allowAnonymous && !store.getters.isAuthenticated) {
    next({
      path: '/',
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
})

export default router
