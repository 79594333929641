<template>
  <v-expansion-panel class="ma-2">
    <v-expansion-panel-header
      ><strong>{{ title }}</strong></v-expansion-panel-header
    >
    <v-expansion-panel-content>
      <v-chip-group column>
        <v-chip v-for="(keyword, index) in keywords" :key="index" pill>
          {{ keyword }}
        </v-chip>
      </v-chip-group>
      <v-col>
        <v-row class="my-1">
          Total number of articles: {{ formatNumber(articleCount) }}
        </v-row>
        <br />
        <v-row class="my-1">
          <v-btn
            elevation="2"
            color="secondary"
            class="float-right"
            :to="campaignRoute"
          >
            Open
          </v-btn>
        </v-row>
      </v-col>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { CAMPAIGN_ROUTE } from '../../router'
import internationalisationMixin from '../../../../mixins/internationalisationMixin'

export default {
  name: 'DashboardCampaignListItem',
  mixins: [internationalisationMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    keywords: {
      type: Array,
      required: true,
    },
    origins: {
      type: Array,
      required: true,
    },
    articleCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    campaignRoute: function() {
      return {
        name: CAMPAIGN_ROUTE.name,
        params: { id: this.id },
      }
    },
  },
}
</script>

<style scoped></style>
