<template>
  <div>
    <v-flex class="text-right" min-height="500">
      <v-menu
        max-height="500"
        v-model="filter"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        transition="slide-x-reverse-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn large elevation="2" color="secondary" v-on="on">
            Filter
          </v-btn>
        </template>
        <v-card outlined tile min-width="500" max-width="500">
          <v-toolbar dark>
            <v-toolbar-title> Select filters</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="filter = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-col>
            <v-row>
              <v-container></v-container>
            </v-row>
            <v-row>
              <v-container>
                <v-chip-group
                  light
                  v-model="filterData.keywords"
                  column
                  multiple
                >
                  <v-chip
                    filter
                    v-for="(keyword, index) in keywords"
                    :key="index"
                  >
                    {{ keyword }}
                  </v-chip>
                </v-chip-group>
              </v-container>
            </v-row>
          </v-col>
          <v-card-actions>
            <v-btn elevation="2" color="secondary" @click="applyFilter"
              >Apply
            </v-btn>
            <v-btn elevation="2" @click="clearFilter">Clear</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-flex>
  </div>
</template>

<script>
import { DEFAULT_CAMPAIGNS_QUERY } from '@/store/modules/dashboard.store'

export default {
  name: 'DashboardCampaignFilter',
  props: {
    keywords: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filter: false,
      filterData: { ...DEFAULT_CAMPAIGNS_QUERY },
    }
  },
  methods: {
    applyFilter() {
      let selectedKeywords = this.filterData.keywords.map(
        (keyword) => this.keywords[keyword]
      )
      this.$store
        .dispatch('dashboard/campaigns/applyCampaignFilter', {
          keywords: [...selectedKeywords],
        })
        .then(() => this.$store.dispatch('dashboard/campaigns/fetchCampaigns'))
      this.filter = false
    },
    clearFilter() {
      this.filterData = { ...DEFAULT_CAMPAIGNS_QUERY }
      this.$store
        .dispatch('dashboard/campaigns/applyCampaignFilter', this.filterData)
        .then(() => this.$store.dispatch('dashboard/campaigns/fetchCampaigns'))
      this.filter = false
    },
  },
}
</script>

<style scoped></style>
