<template>
  <v-card tile :to="route" light>
    <v-list-item two-line>
      <v-list-item-avatar>
        <v-icon>subject</v-icon>
      </v-list-item-avatar>
      <v-list-item-content wrap>
        {{ translated_title }}
        <v-list-item-subtitle>
          <v-row>
            <v-col>{{ language }}</v-col>
            <v-col>{{ formatDateTime(date) }}</v-col>
            <v-col>{{ source_country }}</v-col>
            <v-col>{{ domain }}</v-col>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import { ARTICLE_ROUTE } from '../../router'
import internationalisationMixin from '../../../../mixins/internationalisationMixin'

export default {
  name: 'DashboardArticleListItem',
  mixins: [internationalisationMixin],
  props: {
    id: { type: Number, required: true },
    title: String,
    translated_title: String,
    language: String,
    date: String,
    source_country: String,
    domain: String,
  },
  computed: {
    route: function() {
      return {
        name: ARTICLE_ROUTE.name,
        params: { id: this.id },
      }
    },
  },
}
</script>

<style module lang="scss"></style>
