<template>
  <div v-if="!showingAll && appliedFilter">
    <v-chip
      v-if="appliedFilter.term"
      class="mx-1"
      color="deep-purple accent-4"
      text-color="white"
    >
      <v-icon left>search</v-icon>
      {{ appliedFilter.term }}
    </v-chip>
    <v-chip
      v-if="appliedFilter.language && appliedFilter.language.length > 0"
      class="mx-1"
      color="primary"
    >
      <v-icon left>mic</v-icon>
      {{ renderStringList(appliedFilter.language) }}
    </v-chip>

    <v-chip
      v-if="appliedFilter.country && appliedFilter.country.length > 0"
      class="mx-1"
      color="secondary"
    >
      <v-icon left>map</v-icon>
      {{ renderStringList(appliedFilter.country) }}
    </v-chip>

    <v-chip
      v-if="appliedFilter.dates && appliedFilter.dates.length > 0"
      class="mx-1"
    >
      <v-icon color="accent-4" left>date_range </v-icon>
      {{ renderDates(appliedFilter.dates) }}
    </v-chip>
  </div>
</template>

<script>
import internationalisationMixin from '../../../../../mixins/internationalisationMixin'
import { sortBy } from 'lodash'

export default {
  name: 'DashboardNarrativeClosedFilter',
  mixins: [internationalisationMixin],
  props: {
    appliedFilter: null,
    showingAll: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    renderDates(dates) {
      let convertedDates = []
      dates.forEach((date) => {
        convertedDates.push(new Date(date))
      })
      if (convertedDates.length > 1) {
        const sorted = sortBy(convertedDates)
        return `From ${this.formatDate(sorted[0])} till ${this.formatDate(
          sorted[1]
        )}`
      }
      return `${this.formatDate(convertedDates[0])}`
    },
    renderStringList(values) {
      return values.join(', ')
    },
  },
}
</script>
<style scoped>
.no-hover-chip {
  background-color: initial;
}
.no-hover-chip:hover {
  background-color: initial;
}
</style>
