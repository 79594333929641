import BaseApiGateway from '../../../core/BaseApiGateway'

const resource = '/dashboard'
export default {
  findInteractionsForArticle(articleId) {
    return BaseApiGateway.get(`${resource}/impact/article/${articleId}`)
  },
  findInteractionsForCampaign(campaignId) {
    return BaseApiGateway.get(`${resource}/impact/campaign/${campaignId}`)
  },
  findInteractionsChartDataForCampaign(campaignId) {
    return BaseApiGateway.get(`${resource}/impact/campaign/${campaignId}/chart`)
  },
  findInteractionsChartDataForArticle(articleId) {
    return BaseApiGateway.get(`${resource}/impact/article/${articleId}/chart`)
  },
  findCampaign(campaignId) {
    return BaseApiGateway.get(`${resource}/campaign/${campaignId}`)
  },
  findArticle(articleId) {
    return BaseApiGateway.get(`${resource}/article/${articleId}`)
  },
  search(searchQuery) {
    return BaseApiGateway.post(`${resource}/search`, searchQuery)
  },
  searchSummary(searchQuery) {
    return BaseApiGateway.post(`${resource}/search/summary`, searchQuery)
  },
  annotateArticle(feedback) {
    if (feedback) {
      return BaseApiGateway.post(`${resource}/article/annotate`, feedback)
    }
  },
  findArticleAnnotation(articleId) {
    return BaseApiGateway.get(`${resource}/article/${articleId}/annotation`)
  },

  findArticleAnnotations(analyst) {
    return analyst
      ? BaseApiGateway.get(`${resource}/history/expert/${analyst}`)
      : BaseApiGateway.get(`${resource}/history/me`)
  },
  findOrganisationAnnotations() {
    return BaseApiGateway.get(`${resource}/organisation/annotations`)
  },
  findOrganisationAnalysts() {
    return BaseApiGateway.get(`${resource}/organisation/annotators`)
  },
  fetchAnalystOverview(analyst) {
    return BaseApiGateway.get(
      `${resource}/organisation/annotators/${analyst}/overview`
    )
  },
}
