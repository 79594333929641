import Vue from 'vue'
import Vuex from 'vuex'
import dashboard from './modules/dashboard.store'
import auth from './modules/auth.store'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

const state = {
  cancelTokens: [],
}
const getters = {
  cancelTokens(state) {
    return state.cancelTokens
  },
}
const mutations = {
  CANCEL_TOKEN_ADDED(state, token) {
    state.cancelTokens.push(token)
  },
  CANCEL_TOKENS_CLEARED(state) {
    state.cancelTokens = []
  },
}
const actions = {
  addCancelTokens({ commit }, token) {
    commit('CANCEL_TOKEN_ADDED', token)
  },
  clearCancelTokens({ commit }) {
    commit('CANCEL_TOKENS_CLEARED')
  },
  cancelPendingRequests({ commit, state }) {
    // Cancel all request where a token exists
    state.cancelTokens.forEach((request) => {
      if (request.cancel) {
        request.cancel()
      }
    })

    // Reset the cancelTokens store
    commit('CANCEL_TOKENS_CLEARED')
  },
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    dashboard,
    auth,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})
