<template>
  <v-container fluid>
    <v-row>
      <v-spacer />
      <v-col cols="4">
        <v-card outlined tile>
          <v-toolbar dark>
            <v-toolbar-title>Users</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-data-table
              :headers="analystHeaders"
              :items="analysts"
              :loading="loadingAnalysts"
              class="elevation-1"
              :page.sync="pagination.page"
              @pagination="updatePage"
            >
              <template v-slot:item.username="{ item }">
                <a
                  v-on:click="showAnalyst(item.username)"
                  style="text-decoration: none;"
                >
                  {{ item.username }}
                </a>
              </template>
              <template v-slot:item.created_date="{ item }">
                <span>{{ formatDateTime(item.created_date) }}</span>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
        <v-spacer></v-spacer>
        <v-card v-if="analystOverview" :loading="loadingAnalysts" outlined tile>
          <v-toolbar dark>
            <v-toolbar-title>
              Viewing details for
              {{ analystOverview.username }}
            </v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col>Marked as disinformation</v-col>
              <v-col>{{ analystOverview.debunks }}</v-col>
            </v-row>
            <v-row>
              <v-col>Marked as not disinformation</v-col>
              <v-col>{{ analystOverview.truths }}</v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined tile>
          <v-toolbar dark>
            <v-toolbar-title>History</v-toolbar-title>
          </v-toolbar>
          <v-container v-if="!filteredAnnotations">
            <v-data-table
              :headers="historyHeaders"
              :items="annotations"
              :loading="loadingAnnotations"
              group-by="username"
              class="elevation-1"
              show-group-by
              :page.sync="pagination.page"
              @pagination="updatePage"
            >
              <template v-slot:group.header="{ items, isOpen, toggle }">
                <th colspan="3">
                  <v-icon @click="toggle"
                    >{{ isOpen ? 'remove' : 'add' }}
                  </v-icon>
                  User: {{ items[0].username }}
                </th>
              </template>
              <template v-slot:item.feedback="{ item }">
                <span v-if="item.feedback" style="color: red;">YES</span>
                <span v-else style="color: green;">NO</span>
              </template>
              <template v-slot:item.translated_title="{ item }">
                <a
                  style="color: black; text-decoration: none;"
                  :href="`/dashboard/article/${item.article_id}`"
                  target="_blank"
                  >{{ item.translated_title }}</a
                >
              </template>
              <template v-slot:item.created_date="{ item }">
                <span>{{ formatDateTime(item.created_date) }}</span>
              </template>
            </v-data-table>
          </v-container>
          <v-container v-if="filteredAnnotations">
            <a
              style="color: black; text-decoration: none;"
              v-on:click="showAllAnnotations"
            >
              Show all
            </a>
            <v-data-table
              :headers="historyHeaders"
              :items="filteredAnnotations"
              :loading="loadingAnnotations"
              group-by="username"
              class="elevation-1"
              show-group-by
              :page.sync="pagination.page"
              @pagination="updatePage"
            >
              <template v-slot:group.header="{ items, isOpen, toggle }">
                <th colspan="3">
                  <v-icon @click="remove(items[0].username, toggle, isOpen)"
                    >{{ isOpen ? 'remove' : 'add' }}
                  </v-icon>
                  User: {{ items[0].username }}
                </th>
              </template>
              <template v-slot:item.feedback="{ item }">
                <span v-if="item.feedback" style="color: red;">YES</span>
                <span v-else style="color: green;">NO</span>
              </template>
              <template v-slot:item.translated_title="{ item }">
                <a
                  style="color: black; text-decoration: none;"
                  :href="`/dashboard/article/${item.article_id}`"
                  target="_blank"
                  >{{ item.translated_title }}</a
                >
              </template>
              <template v-slot:item.created_date="{ item }">
                <span>{{ formatDateTime(item.created_date) }}</span>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
      <v-spacer />
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import internationalisationMixin from '../../../mixins/internationalisationMixin'

export default {
  name: 'DashboardOrganisationHistory',
  components: {},
  mixins: [internationalisationMixin],
  data() {
    return {
      historyHeaders: [
        {
          text: 'Title',
          align: 'start',
          value: 'translated_title',
          groupable: false,
        },
        { text: 'User', value: 'username' },
        { text: 'Disinfo', value: 'feedback', groupable: false },
        { text: 'Annotation date', value: 'created_date', groupable: false },
      ],
      analystHeaders: [
        {
          text: 'User',
          align: 'start',
          value: 'username',
        },
        { text: 'Articles reviewed', value: 'annotation_count' },
        { text: 'Active since', value: 'created_date' },
      ],
      filteredAnnotations: null,
      excludedUsernames: [],
      pagination: {},
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.$store.dispatch('dashboard/fetchOrganisationAnnotations')

    this.$store.dispatch('dashboard/fetchOrganisationAnalysts')
  },
  methods: {
    showAnalyst(username) {
      this.$store.dispatch('dashboard/fetchAnalystOverview', username)
      this.pagination.page = 1
      this.filteredAnnotations = this.annotations.filter((annotation) => {
        return annotation.username === username
      })
    },
    showAllAnnotations() {
      this.pagination.page = 1
      this.filteredAnnotations = null
    },
    updatePage() {},
  },

  computed: mapState({
    loadingAnnotations: (state) => state.dashboard.loadingAnnotations,
    loadingAnalysts: (state) => state.dashboard.loadingAnalysts,
    annotations: (state) => state.dashboard.organisation.annotations,
    analysts: (state) => state.dashboard.organisation.analysts,
    analystOverview: (state) => state.dashboard.organisation.analystOverview,
    loadingAnalystOverview: (state) => state.dashboard.loadingAnalystOverview,
  }),
}
</script>

<style lang="scss" module></style>
