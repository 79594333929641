import DashboardService from '../../../modules/dashboard/service/DashboardService'
import {
  DEFAULT_ARTICLE_FILTER,
  DEFAULT_CAMPAIGN_INTERACTIONS_MEDIUMS,
} from '../dashboard.store'
import utils from '../../../core/utils'

const state = () => initialState()

const initialState = () => ({
  campaignId: null,
  languages: [],
  origins: [],
  mapOrigins: [],
  keywords: [],
  loadingCampaign: false,
  loadingArticles: false,
  articles: [],
  articleFilter: { ...DEFAULT_ARTICLE_FILTER },
  campaign: {},
  loadingCampaignSummary: false,
  loadingCampaignArticleQuerySummary: false,
  campaignSummary: {},
  campaignArticleQuerySummary: {},
  loadingCampaignInteractions: false,
  campaignInteractions: [...DEFAULT_CAMPAIGN_INTERACTIONS_MEDIUMS],
})

const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  CAMPAIGN_ID_SET(state, campaignId) {
    state.campaignId = utils.deepCopy(campaignId)
  },
  LANGUAGES_ADDED(state, languages) {
    state.languages = utils.deepCopy(languages)
  },
  ORIGINS_ADDED(state, origins) {
    state.origins = utils.deepCopy(origins)
  },
  KEYWORDS_ADDED(state, keywords) {
    state.keywords = utils.deepCopy(keywords)
  },
  CAMPAIGN_LOADING_TOGGLED(state, value) {
    state.loadingCampaign = value
  },
  CAMPAIGN_SET(state, campaign) {
    state.campaign = utils.deepCopy(campaign)
  },
  CAMPAIGN_SUMMARY_LOADING_TOGGLED(state, value) {
    state.loadingCampaignSummary = value
  },
  CAMPAIGN_ARTICLE_SUMMARY_LOADING_TOGGLED(state, value) {
    state.loadingCampaignArticleQuerySummary = value
  },
  CAMPAIGN_SUMMARY_SET(state, summary) {
    state.campaignSummary = utils.deepCopy(summary)
    state.campaignSummary.minDate = state.campaignSummary.minPublishDate
    state.campaignSummary.maxDate = state.campaignSummary.maxPublishDate
  },
  CAMPAIGN_ARTICLE_QUERY_SUMMARY_SET(state, summary) {
    state.campaignArticleQuerySummary = utils.deepCopy(summary)
    state.campaignArticleQuerySummary.minDate = state.campaignSummary.minDate
    state.campaignArticleQuerySummary.maxDate = state.campaignSummary.maxDate
  },
  CAMPAIGN_INTERACTIONS_LOADING_TOGGLED(state, value) {
    state.loadingCampaignInteractions = value
  },
  CAMPAIGN_INTERACTIONS_SET(state, interactions) {
    const localCopy = utils.deepCopy(interactions)
    for (let i = 0; i < state.campaignInteractions.length; i++) {
      state.campaignInteractions[i].interactions =
        localCopy.mediums[state.campaignInteractions[i].platform.toLowerCase()]
    }
  },
  ARTICLES_LOADING_TOGGLED(state, value) {
    state.loadingArticles = value
  },
  ARTICLE_FILTER_APPLIED(state, filter) {
    state.articles = []
    state.articleFilter = utils.deepCopy(filter)
  },
  ARTICLES_SET(state, articles) {
    state.articles = utils.deepCopy(articles)
  },
  ARTICLES_ADDED(state, articles) {
    state.articles.push(...utils.deepCopy(articles))
  },
}

const actions = {
  fetchCampaign({ commit, dispatch }, campaignId) {
    return new Promise((resolve) => {
      commit('CAMPAIGN_LOADING_TOGGLED', true)
      commit('CAMPAIGN_ID_SET', campaignId)
      dispatch('fetchCampaignSummary')
      dispatch('fetchArticles')
      DashboardService.findCampaign(campaignId).then((response) => {
        commit('CAMPAIGN_SET', response.data)
        commit('CAMPAIGN_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },
  fetchCampaignSummary({ commit, state }) {
    return new Promise((resolve) => {
      commit('CAMPAIGN_SUMMARY_LOADING_TOGGLED', true)
      const campaignSummaryQuery = {
        campaignId: state.campaignId,
        type: 'campaigns',
      }
      DashboardService.searchSummary(campaignSummaryQuery).then((response) => {
        commit('CAMPAIGN_SUMMARY_SET', response.data)
        commit('ORIGINS_ADDED', response.data.origins)
        commit('KEYWORDS_ADDED', response.data.keywords)
        commit('LANGUAGES_ADDED', response.data.languages)
        commit('CAMPAIGN_SUMMARY_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },
  applyArticleFilter({ commit, dispatch }, filter) {
    return new Promise(() => {
      commit('ARTICLE_FILTER_APPLIED', filter)
      return dispatch('fetchArticles').then(() =>
        dispatch('fetchCampaignArticleQuerySummary')
      )
    })
  },
  fetchCampaignArticleQuerySummary({ commit, state }) {
    return new Promise((resolve) => {
      const campaignArticlesSummaryQuery = {
        ...utils.deepCopy(state.articleFilter),
        campaignId: state.campaignId,
        type: 'articles',
      }
      commit('CAMPAIGN_ARTICLE_SUMMARY_LOADING_TOGGLED', true)
      DashboardService.searchSummary(campaignArticlesSummaryQuery).then(
        (response) => {
          commit('CAMPAIGN_ARTICLE_QUERY_SUMMARY_SET', response.data)
          commit('CAMPAIGN_ARTICLE_SUMMARY_LOADING_TOGGLED', false)
          resolve(response.data)
        }
      )
    })
  },
  fetchArticles({ commit, getters, state }) {
    return new Promise((resolve) => {
      const articlesQuery = {
        startArticleId: getters.getLastLoadedArticleId,
        pageSize: 5,
        offset: 0,
        campaignId: state.campaignId,
        type: 'articles',
        filter: utils.deepCopy(state.articleFilter),
      }
      commit('ARTICLES_LOADING_TOGGLED', true)
      DashboardService.search(articlesQuery).then((response) => {
        commit('ARTICLES_SET', response.data)
        commit('ARTICLES_LOADING_TOGGLED', false)
        resolve(response.data)
      })
    })
  },

  fetchNextArticles({ commit, getters, state }) {
    return new Promise((resolve) => {
      commit('ARTICLES_LOADING_TOGGLED', true)
      const articlesQuery = {
        startArticleId: getters.getLastLoadedArticleId,
        pageSize: 5,
        offset: state.articles.length,
        campaignId: state.campaignId,
        type: 'articles',
        filter: { ...state.articleFilter },
      }
      DashboardService.search(articlesQuery).then((response) => {
        commit('ARTICLES_ADDED', response.data)
        commit('ARTICLES_LOADING_TOGGLED', false)
        resolve(response.data)
      })
    })
  },
  fetchCampaignInteractions({ commit, state }) {
    return new Promise((resolve) => {
      commit('CAMPAIGN_INTERACTIONS_LOADING_TOGGLED', true)
      DashboardService.findInteractionsForCampaign(state.campaignId).then(
        (response) => {
          commit('CAMPAIGN_INTERACTIONS_SET', response.data)
          commit('CAMPAIGN_INTERACTIONS_LOADING_TOGGLED', false)
        }
      )
      resolve()
    })
  },
}

const getters = {
  getLastLoadedArticleId: (state) => {
    const article = state.articles[state.articles.length - 1]
    if (article) {
      return article.id
    }
    return -1
  },
  getCampaignId: (state) => {
    return state.campaignId
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
