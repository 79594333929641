<template>
  <div>
    <keep-alive>
      <DashboardArticleOpenFilter
        v-if="showFilter"
        v-bind:languages="languages"
        v-bind:origins="origins"
        v-bind:max-date="maxArticlePublishDate"
        v-bind:min-date="campaignArticleQuerySummary.minDate"
        v-bind:applied-filter="appliedFilter"
        @apply-filter="applyFilter"
        @clear-filter="clearFilter"
        @toggle-filter="toggleFilter"
      />
      <DashboardArticleClosedFilter
        v-else
        v-bind:applied-filter="appliedFilter"
        v-bind:showing-all="showingAll"
      />
    </keep-alive>
    <div class="my-3">
      <v-btn
        v-if="!showFilter"
        elevation="2"
        color="secondary"
        @click="toggleFilter"
        class="mr-1"
      >
        {{ filterLabel }}
      </v-btn>
      <v-btn
        v-if="!showFilter && !showingAll"
        elevation="2"
        @click="clearFilter"
        class="mx-1"
      >
        Clear filter
      </v-btn>
    </div>
    <p class="my-3">
      Found
      <span v-if="loadingCampaignArticleQuerySummary">
        <v-progress-circular indeterminate></v-progress-circular>
      </span>
      <span v-else>
        <strong>{{
          formatNumber(campaignArticleQuerySummary.articleCount)
        }}</strong>
      </span>
      disinformation articles
    </p>
  </div>
</template>

<script>
import DashboardArticleOpenFilter from './DashboardArticleOpenFilter'
import DashboardArticleClosedFilter from './DashboardArticleClosedFilter'
import { mapState } from 'vuex'
import internationalisationMixin from '../../../../../mixins/internationalisationMixin'

export default {
  name: 'DashboardArticleFilter',
  components: { DashboardArticleOpenFilter, DashboardArticleClosedFilter },
  mixins: [internationalisationMixin],

  props: {
    languages: {
      type: Array,
      default: null,
    },
    origins: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    showFilter: false,
    appliedFilter: { country: [], dates: [], language: [], term: null },
    maxArticlePublishDate: new Date().toISOString().slice(0, 10),
  }),
  created() {
    this.$store.dispatch('dashboard/campaign/fetchCampaignArticleQuerySummary')
  },
  computed: {
    showingAll() {
      return (
        !this.appliedFilter ||
        (this.appliedFilter.country.length === 0 &&
          this.appliedFilter.dates.length === 0 &&
          this.appliedFilter.language.length === 0 &&
          !this.appliedFilter.term)
      )
    },
    filterLabel() {
      if (this.showFilter) return 'Close filter'
      return 'Open filter'
    },
    ...mapState({
      campaignArticleQuerySummary: (state) =>
        state.dashboard.campaign.campaignArticleQuerySummary,
      articleFilter: (state) => state.dashboard.campaign.articleFilter,
      loadingArticles: (state) => state.dashboard.campaign.loadingArticles,
      loadingCampaignArticleQuerySummary: (state) =>
        state.dashboard.campaign.loadingCampaignArticleQuerySummary,
    }),
  },

  methods: {
    applyFilter(event) {
      let filterData = { ...event }
      this.$store.dispatch('dashboard/campaign/applyArticleFilter', filterData)
      this.appliedFilter = filterData
    },
    clearFilter() {
      if (!this.showingAll) {
        this.applyFilter({ country: [], dates: [], language: [], term: null })
      }
    },

    toggleFilter() {
      this.showFilter = !this.showFilter
    },
  },
}
</script>
