import DashboardService from '../../../modules/dashboard/service/DashboardService'
import {
  DEFAULT_ARTICLE_FILTER,
  DEFAULT_NARRATIVE_QUERY,
} from '../dashboard.store'
import utils from '../../../core/utils'

const initialNarrativesState = () => ({
  analystLanguage: undefined,
  allLanguages: [],
  allOrigins: [],
  loadingNarratives: false,
  loadingNarrativesSummary: false,
  narratives: [],
  narrativesFilter: utils.deepCopy(DEFAULT_ARTICLE_FILTER),
  narrativesQuery: utils.deepCopy(DEFAULT_NARRATIVE_QUERY),
  narrativesCount: 0,
  languages: [],
  origins: [],
  mapOrigins: [],
  keywords: [],
  minArticlePublishDate: null,
  maxArticlePublishDate: null,
})
const state = () => initialNarrativesState()

const mutations = {
  MAP_ORIGINS_SET(state, origins) {
    state.mapOrigins = utils.deepCopy(origins)
  },
  LANGUAGES_ADDED(state, languages) {
    state.languages = utils.deepCopy(languages)
  },
  ORIGINS_ADDED(state, origins) {
    state.origins = utils.deepCopy(origins)
  },
  KEYWORDS_ADDED(state, keywords) {
    state.keywords = utils.deepCopy(keywords)
  },
  NARRATIVES_LOADING_TOGGLED(state, value) {
    state.loadingNarratives = value
  },
  NARRATIVES_SUMMARY_LOADING_TOGGLED(state, value) {
    state.loadingNarrativesSummary = value
  },
  ALL_LANGUAGES_ADDED(state, value) {
    state.allLanguages = utils.deepCopy(value)
  },
  ALL_ORIGINS_ADDED(state, value) {
    state.allOrigins = utils.deepCopy(value)
  },
  MIN_ARTICLE_PUBLISH_DATE_ADDED(state, value) {
    state.minArticlePublishDate = utils.deepCopy(value)
  },
  MAX_ARTICLE_PUBLISH_DATE_ADDED(state, value) {
    state.maxArticlePublishDate = utils.deepCopy(value)
  },
  NARRATIVE_FILTER_APPLIED(state, filter) {
    state.narrativesQuery = utils.deepCopy(filter)
  },
  NARRATIVES_CLEARED(state) {
    state.narratives = []
  },
  NARRATIVES_SET(state, narratives) {
    state.narratives = utils.deepCopy(narratives)
  },
  NARRATIVE_FILTER_SET(state, response) {
    state.narrativesFilter.languages = utils.deepCopy(response.languages)
    state.narrativesFilter.origins = utils.deepCopy(response.origins)
    state.narrativesFilter.minDate = utils.deepCopy(response.minPublishDate)
    state.narrativesFilter.maxDate = utils.deepCopy(response.maxPublishDate)
  },
  NARRATIVES_ADDED(state, narratives) {
    state.narratives.push(...utils.deepCopy(narratives))
  },
  NARRATIVES_COUNT_SET(state, count) {
    state.narrativesCount = count
  },
}

const actions = {
  fetchNarrativesSummary({ commit }, filter) {
    return new Promise((resolve) => {
      let query = utils.deepCopy(filter)
      query.type = 'articles'
      commit('NARRATIVES_SUMMARY_LOADING_TOGGLED', true)
      DashboardService.searchSummary(query).then((response) => {
        commit('NARRATIVES_COUNT_SET', response.data.articleCount)
        commit('ORIGINS_ADDED', response.data.origins)
        commit('MAP_ORIGINS_SET', response.data.origins)
        commit('KEYWORDS_ADDED', response.data.keywords)
        commit('LANGUAGES_ADDED', response.data.languages)
        commit('NARRATIVE_FILTER_SET', response.data)
        commit('NARRATIVES_SUMMARY_LOADING_TOGGLED', false)
        resolve(response.data)
      })
    })
  },
  fetchNarrativesSummaryData({ state }, filter) {
    return new Promise((resolve) => {
      let query = utils.deepCopy(filter)
      query.type = 'articles'
      if (state.analystLanguage) {
        query.analystLanguage = state.analystLanguage
        query.language = state.analystLanguage
      }
      DashboardService.searchSummary(query).then((response) => {
        resolve(response.data)
      })
    })
  },
  fetchTotalNarrativesSummary({ commit }) {
    return new Promise((resolve) => {
      let query = utils.deepCopy(DEFAULT_NARRATIVE_QUERY)
      query.type = 'articles'
      query.language = []
      DashboardService.searchSummary(query).then((response) => {
        commit('ALL_LANGUAGES_ADDED', response.data.languages)
        commit('ALL_ORIGINS_ADDED', response.data.origins)
        commit(
          'MIN_ARTICLE_PUBLISH_DATE_ADDED',
          utils.deepCopy(response.data.minPublishDate)
        )
        commit(
          'MAX_ARTICLE_PUBLISH_DATE_ADDED',
          utils.deepCopy(response.data.maxPublishDate)
        )
      })
      resolve()
    })
  },
  clearNarratives({ commit }) {
    return new Promise((resolve) => {
      commit('NARRATIVES_CLEARED')
      resolve()
    })
  },
  fetchNarratives({ commit, getters, state }, sortByImpact) {
    return new Promise((resolve) => {
      commit('NARRATIVES_LOADING_TOGGLED', true)
      const narrativeQuery = {
        startArticleId: getters.getLastLoadedNarrativeId,
        sort_by_impact: sortByImpact,
        pageSize: 20,
        offset: 0,
        type: 'articles',
        filter: utils.deepCopy(state.narrativesQuery),
      }

      DashboardService.search(narrativeQuery).then((response) => {
        commit('NARRATIVES_SET', response.data)
        commit('NARRATIVES_LOADING_TOGGLED', false)
        resolve(response.data)
      })
    })
  },

  fetchNextNarratives({ commit, getters, state }) {
    return new Promise((resolve) => {
      commit('NARRATIVES_LOADING_TOGGLED', true)
      const narrativeQuery = {
        startArticleId: getters.getLastLoadedNarrativeId,
        pageSize: 20,
        offset: state.narratives.length,
        type: 'articles',
        filter: utils.deepCopy(state.narrativesQuery),
      }
      if (state.analystLanguage) {
        narrativeQuery.filter.languages = []
        narrativeQuery.filter.languages.push(state.analystLanguage)
      }
      DashboardService.search(narrativeQuery).then((response) => {
        commit('NARRATIVES_ADDED', response.data)
        commit('NARRATIVES_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },
  applyNarrativeFilter({ state, commit, dispatch }, filter) {
    return new Promise(() => {
      let query = utils.deepCopy(state.narrativesQuery)
      if (state.analystLanguage) {
        query.analystLanguage = state.analystLanguage
        query.language = state.analystLanguage
      }
      let updatedFilter = { ...query, ...utils.deepCopy(filter) }

      commit('NARRATIVE_FILTER_APPLIED', updatedFilter)
      commit('NARRATIVES_SET', [])
      dispatch('fetchNarrativesSummary', updatedFilter)
      return dispatch('fetchNarratives')
    })
  },
}

const getters = {
  getLastLoadedNarrativeId: (state) => {
    if (state.narratives.length > 0) {
      const narrative = state.narratives[state.narratives.length - 1]
      if (narrative) {
        return narrative.id
      }
    }

    return -1
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
