<template>
  <div>
    <v-text-field
      prepend-icon="search"
      name="Search"
      label="Search text"
      v-model.trim="appliedFilter.term"
      single-line
      color="deep-purple accent-4"
    ></v-text-field>
    <v-select
      multiple
      chips
      clearable
      item-color="black"
      v-model="appliedFilter.language"
      :items="languages"
      label="Select language"
      dense
      ref="languageSelect"
      prepend-icon="mic"
      color="primary"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip :input-value="selected" color="primary">
          <strong>{{ item }}</strong>
        </v-chip>
      </template>
      <template v-slot:append-outer>
        <v-divider class="mt-2"></v-divider>
        <v-list-item ripple @click="closeLanguageSelect">
          <v-list-item-content>
            <v-list-item-title>
              <v-btn elevation="2" color="secondary">
                {{ originsSelectActionTitle }}
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>

    <v-select
      multiple
      chips
      clearable
      deletable-chips
      item-color="secondary"
      hint="Select multiple origins"
      persistent-hint
      v-model="appliedFilter.country"
      :items="origins"
      label="Origin"
      color="secondary"
      dense
      ref="countrySelect"
      prepend-icon="map"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip :input-value="selected" color="secondary">
          <strong>{{ item }}</strong>
        </v-chip>
      </template>
      <template v-slot:append-outer>
        <v-divider class="mt-2"></v-divider>
        <v-list-item ripple @click="closeCountrySelect">
          <v-list-item-content>
            <v-list-item-title>
              <v-btn elevation="2" color="secondary">
                {{ originsSelectActionTitle }}
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
    <v-menu
      ref="datePickerMenu"
      v-model="datePickerMenu"
      :close-on-content-click="false"
      :return-value.sync="appliedFilter.dates"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="appliedFilter.dates"
          label="Article publish date range"
          prepend-icon="date_range"
          color="accent-4"
          readonly
          v-on="on"
        >
        </v-text-field>
      </template>
      <v-date-picker
        dark
        v-model="appliedFilter.dates"
        v-bind:min="minDate"
        v-bind:max="maxDate"
        range
        no-title
        scrollable
        first-day-of-week="1"
      >
        <v-spacer></v-spacer>
        <v-btn text @click="datePickerMenu = false">Cancel</v-btn>
        <v-btn text @click="appliedFilter.dates = []">Clear</v-btn>
        <v-btn text @click="$refs.datePickerMenu.save(appliedFilter.dates)">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <div class="py-2">
      <v-btn class="mx-1" elevation="2" color="secondary" @click="applyFilter"
        >Apply</v-btn
      >
      <v-btn class="mx-1" elevation="2" color="secondary" @click="toggleFilter"
        >Close</v-btn
      >
      <v-btn class="mx-1" elevation="2" @click="clearFilter">Clear</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardNarrativeOpenFilter',
  props: {
    languages: {
      type: Array,
      required: true,
    },
    origins: {
      type: Array,
      required: true,
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    appliedFilter: {
      type: Object,
      default: () => {
        return { country: [], dates: [], language: [], term: null }
      },
    },
  },
  data: () => ({
    filter: false,
    datePickerMenu: false,
    originsSelectActionTitle: 'Select',
  }),
  watch: {},

  methods: {
    closeCountrySelect() {
      this.$refs.countrySelect.blur()
    },
    closeLanguageSelect() {
      this.$refs.languageSelect.blur()
    },
    applyFilter() {
      this.$emit('apply-filter', this.appliedFilter)
      this.$emit('toggle-filter')
    },
    toggleFilter() {
      this.$emit('toggle-filter')
    },
    clearFilter() {
      this.appliedFilter = { country: [], dates: [], language: [], term: null }
      this.$emit('clear-filter', this.appliedFilter)
    },
  },
}
</script>
