<template>
  <div>
    <keep-alive>
      <DashboardNarrativeOpenFilter
        v-if="showFilter"
        v-bind:languages="languages"
        v-bind:origins="origins"
        v-bind:min-date="minArticlePublishDate"
        v-bind:max-date="maxArticlePublishDate"
        v-bind:applied-filter="appliedFilter"
        @apply-filter="applyFilter"
        @clear-filter="clearFilter"
        @toggle-filter="toggleFilter"
      />
      <DashboardNarrativeClosedFilter
        v-else
        v-bind:applied-filter="appliedFilter"
        v-bind:showing-all="showingAll"
      />
    </keep-alive>
    <div class="my-3">
      <v-btn
        v-if="!showFilter"
        elevation="2"
        color="secondary"
        @click="toggleFilter"
        class="mr-1"
      >
        {{ filterLabel }}
      </v-btn>
      <v-btn
        v-if="!showFilter && !showingAll"
        elevation="2"
        @click="clearFilter"
        class="mx-1"
      >
        Clear filter
      </v-btn>
    </div>
    <p class="my-3">
      Found
      <span v-if="loadingNarrativesSummary">
        <v-progress-circular indeterminate></v-progress-circular>
      </span>
      <span v-else>
        <strong>{{ formatNumber(narrativesCount) }}</strong>
      </span>
      disinformation articles
    </p>
  </div>
</template>

<script>
import DashboardNarrativeOpenFilter from './DashboardNarrativeOpenFilter'
import DashboardNarrativeClosedFilter from './DashboardNarrativeClosedFilter'
import { mapState } from 'vuex'
import internationalisationMixin from '../../../../../mixins/internationalisationMixin'

export default {
  name: 'DashboardNarrativeFilter',
  components: { DashboardNarrativeOpenFilter, DashboardNarrativeClosedFilter },
  mixins: [internationalisationMixin],

  props: {
    languages: {
      type: Array,
      default: null,
    },
    origins: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    showFilter: false,
    appliedFilter: { country: [], dates: [], language: [], term: null },
    maxArticlePublishDate: new Date().toISOString().slice(0, 10),
  }),
  computed: {
    showingAll() {
      return (
        !this.appliedFilter ||
        (this.appliedFilter.country.length === 0 &&
          this.appliedFilter.dates.length === 0 &&
          this.appliedFilter.language.length === 0 &&
          !this.appliedFilter.term)
      )
    },
    filterLabel() {
      if (this.showFilter) return 'Close filter'
      return 'Open filter'
    },
    ...mapState({
      narrativesFilter: (state) => state.dashboard.narratives.narrativesFilter,
      minArticlePublishDate: (state) =>
        state.dashboard.narratives.minArticlePublishDate,
      analystLanguage: (state) => state.dashboard.narratives.analystLanguage,
      narrativesCount: (state) => state.dashboard.narratives.narrativesCount,
      loadingNarratives: (state) =>
        state.dashboard.narratives.loadingNarratives,
      loadingNarrativesSummary: (state) =>
        state.dashboard.narratives.loadingNarrativesSummary,
    }),
  },

  methods: {
    applyFilter(event) {
      let filterData = { ...event }
      this.$store.dispatch(
        'dashboard/narratives/applyNarrativeFilter',
        filterData
      )
      this.appliedFilter = filterData
    },
    clearFilter() {
      if (!this.showingAll) {
        this.applyFilter({ country: [], dates: [], language: [], term: null })
      }
    },

    toggleFilter() {
      this.showFilter = !this.showFilter
    },
  },
}
</script>
