<template>
  <v-layout column class="ma-2">
    <v-container
      id="scroll-target"
      ref="narrativeContainer"
      style="max-height: 61vh"
      class="overflow-y-auto"
    >
      <v-expansion-panels light v-scroll:#scroll-target="onScrollEnd">
        <DashboardNarrativeListItem
          v-for="narrative in narratives"
          :key="narrative.id"
          v-bind:id="narrative.id"
          v-bind:campaignId="narrative.cluster_id"
          v-bind:title="narrative.translated_title"
          v-bind:text="articleText(narrative)"
          v-bind:country="
            narrative.source_country ? narrative.source_country : ''
          "
          v-bind:language="narrative.language ? narrative.language : ''"
        />
      </v-expansion-panels>
    </v-container>
  </v-layout>
</template>

<script>
import DashboardNarrativeListItem from './DashboardNarrativeListItem'

export default {
  name: 'DashboardCampaignList',
  components: { DashboardNarrativeListItem },
  props: {
    narratives: { type: Array, required: true },
  },
  methods: {
    onScrollEnd(e) {
      if (
        e.target.scrollTop ===
        e.target.scrollHeight - e.target.clientHeight
      ) {
        if (!this.$store.state.dashboard.loadingNarratives) {
          this.$store.dispatch('dashboard/narratives/fetchNextNarratives')
        }
      }
    },
    resetListScroll() {
      if (this.$refs.narrativeContainer) {
        this.$refs.narrativeContainer.scrollTop = 0
      }
    },
    articleText(article) {
      let text = ''
      if (article) {
        if (article.text_translated_coloured) {
          text = article.text_translated_coloured
        } else if (article.translated_text) {
          text = article.translated_text
        }
      }
      return text
    },
  },
}
</script>
