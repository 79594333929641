import DashboardService from '../../../modules/dashboard/service/DashboardService'
import { DEFAULT_CAMPAIGNS_QUERY } from '../dashboard.store'
import utils from '../../../core/utils'

const initialState = () => ({
  loadingCampaigns: false,
  campaigns: [],
  campaignsQuery: { ...DEFAULT_CAMPAIGNS_QUERY },
  campaignFilter: { ...DEFAULT_CAMPAIGNS_QUERY },
  languages: [],
  origins: [],
  mapOrigins: [],
  keywords: [],
})

const state = () => initialState()

const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  CAMPAIGNS_LOADING_TOGGLED(state, value) {
    state.loadingCampaigns = value
  },
  CAMPAIGNS_SET(state, campaigns) {
    state.campaigns = utils.deepCopy(campaigns)
  },
  CAMPAIGNS_ADDED(state, campaigns) {
    state.campaigns.push(...utils.deepCopy(campaigns))
  },
  CAMPAIGNS_CLEARED(state) {
    state.campaigns = []
  },
  CAMPAIGN_FILTER_APPLIED(state, filter) {
    state.campaigns = []
    state.campaignFilter = { ...utils.deepCopy(filter) }
  },
  LANGUAGES_ADDED(state, languages) {
    state.languages = utils.deepCopy(languages)
  },
  ORIGINS_ADDED(state, origins) {
    state.origins = utils.deepCopy(origins)
  },
  MAP_ORIGINS_SET(state, origins) {
    state.mapOrigins = utils.deepCopy(origins)
  },
  MAP_ORIGINS_RESET(state) {
    state.mapOrigins = utils.deepCopy(state.origins)
  },
  KEYWORDS_ADDED(state, keywords) {
    state.keywords = utils.deepCopy(keywords)
  },
}

const actions = {
  applyCampaignFilter({ commit }, filter) {
    return new Promise((resolve) => {
      commit('CAMPAIGN_FILTER_APPLIED', filter)
      resolve()
    })
  },
  clearCampaigns({ commit }) {
    return new Promise((resolve) => {
      commit('CAMPAIGNS_CLEARED')
      resolve()
    })
  },
  fetchCampaigns({ commit, getters, state }, sortByImpact) {
    return new Promise((resolve) => {
      const campaignsQuery = {
        startCampaignId: getters.getLastLoadedCampaignId,
        sort_by_impact: sortByImpact,
        pageSize: 5,
        offset: 0,
        type: 'campaigns',
        filter: utils.deepCopy(state.campaignFilter),
      }
      commit('CAMPAIGNS_LOADING_TOGGLED', true)
      DashboardService.search(campaignsQuery).then((response) => {
        commit('CAMPAIGNS_SET', response.data)
        commit('CAMPAIGNS_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },
  fetchNextCampaigns({ commit, getters, state }, sortByImpact) {
    return new Promise((resolve) => {
      const campaignsQuery = {
        startCampaignId: getters.getLastLoadedCampaignId,
        pageSize: 5,
        sort_by_impact: sortByImpact,
        offset: state.campaigns.length,
        type: 'campaigns',
        filter: { ...utils.deepCopy(state.campaignFilter) },
      }
      commit('CAMPAIGNS_LOADING_TOGGLED', true)
      DashboardService.search(campaignsQuery).then((response) => {
        commit('CAMPAIGNS_ADDED', response.data)
        commit('CAMPAIGNS_LOADING_TOGGLED', false)
      })
      resolve()
    })
  },
  fetchCampaignsSummary({ commit, state }) {
    return new Promise((resolve) => {
      let query = utils.deepCopy(state.campaignsQuery)
      query.type = 'campaigns'
      DashboardService.searchSummary(query).then((response) => {
        commit('ORIGINS_ADDED', response.data.origins)
        commit('MAP_ORIGINS_SET', response.data.origins)
        commit('KEYWORDS_ADDED', response.data.keywords)
        commit('LANGUAGES_ADDED', response.data.languages)
        resolve()
      })
    })
  },

  fetchCampaignsSummaryData({ commit }, filter) {
    commit('CAMPAIGNS_LOADING_TOGGLED', true)
    return new Promise((resolve) => {
      let query = utils.deepCopy(filter)
      query.type = 'campaigns'
      DashboardService.searchSummary(query).then((response) => {
        commit('CAMPAIGNS_LOADING_TOGGLED', false)
        resolve(response.data)
      })
    })
  },

  updateMap({ commit }, origins) {
    return new Promise((resolve) => {
      commit('MAP_ORIGINS_SET', origins)
      commit('MAP_UPDATED')
      resolve()
    })
  },
  resetMapOrigins({ commit }) {
    return new Promise((resolve) => {
      commit('MAP_ORIGINS_RESET')
      commit('MAP_UPDATED')
      resolve()
    })
  },
}

const getters = {
  getLastLoadedCampaignId: (state) => {
    const campaign = state.campaigns[state.campaigns.length - 1]
    if (campaign) {
      return campaign.id
    }
    return -1
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
