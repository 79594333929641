<template>
  <v-card min-height="500px" outlined>
    <v-toolbar dark>
      <v-col cols="5">
        <v-toolbar-title>Article text</v-toolbar-title>
      </v-col>
      <v-col cols="4">
        <v-switch
          class="mt-5 float-right"
          v-model="showOriginal"
          color="primary"
          label="Show original text"
        >
          <template v-slot:label>
            <div><strong class="white--text">Show original text</strong></div>
          </template>
        </v-switch>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="pa-1" md cols="3">
        <div v-if="isLoadingSimilarityMatch">
          Loading disinformation match
          <v-progress-circular indeterminate color="red"></v-progress-circular>
        </div>
        <div v-else>
          <v-btn
            elevation="2"
            light
            small
            :href="similarityMatch.disinformation_url"
            target="_blank"
            >Matched article
          </v-btn>
        </div>
      </v-col>
    </v-toolbar>
    <v-card-text>
      <div class="body-1" style="white-space: pre-line; color: black;">
        <div v-if="showOriginal" v-html="textContent"></div>
        <div v-else v-html="textContent"></div>
      </div>
      <v-spacer />
      <v-btn
        v-if="showReadButton"
        @click="toggleFullText"
        elevation="2"
        color="lighten-2"
        class="mt-3"
        dark
      >
        <div v-if="showFullText">
          Show less
        </div>
        <div v-else>
          Show more
        </div>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
const MAX_TEXT_CONTENT_LENGTH = 1300
export default {
  name: 'DashboardArticleTextContainer',
  props: {
    showOriginal: {
      type: Boolean,
      default: false,
      required: true,
    },
    article: {
      type: Object,
      default: null,
      required: true,
    },
    isLoadingSimilarityMatch: {
      type: Boolean,
      default: false,
      required: true,
    },
    similarityMatch: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      showFullText: false,
    }
  },
  created() {
    let tooltipToggleElement = document.createElement('script')
    tooltipToggleElement.text = `
       function inlineScriptShowDisinformationTooltip() {
        if (
          document.getElementById('disinformation-tooltip').style.display ===
          'none'
        ) {
          document.getElementById('disinformation-tooltip').style.display =
            'block'
        } else {
          document.getElementById('disinformation-tooltip').style.display = 'none'
        }
      }
    `
    document.body.appendChild(tooltipToggleElement)
  },
  computed: {
    articleText: function() {
      let text = ''
      if (this.article) {
        if (this.showOriginal) {
          if (this.article.text_coloured) {
            text = this.article.text_coloured
          } else if (this.article.text) {
            text = this.article.text
          }
        } else {
          if (this.article.text_translated_coloured) {
            text = this.article.text_translated_coloured
          } else if (this.article.translated_text) {
            text = this.article.translated_text
          }
        }
      }
      return text
    },

    textContent: function() {
      if (this.showFullText) {
        if (this.showOriginal)
          return this.applySimilarityMatch(this.articleText)
        return this.articleText
      }
      let text = this.articleText

      if (text.length > MAX_TEXT_CONTENT_LENGTH) {
        text = text.substr(0, MAX_TEXT_CONTENT_LENGTH) + '...'
      }
      if (this.showOriginal) text = this.applySimilarityMatch(text)
      return text
    },
    showReadButton: function() {
      return this.articleText.length > MAX_TEXT_CONTENT_LENGTH
    },
    similarityMatchExplanation: function() {
      if (
        this.similarityMatch.disinformation_text &&
        this.similarityMatch.disinformation_text.length > 0
      ) {
        return this.similarityMatch.disinformation_text
      }
      return this.similarityMatch.disinformation_summary
    },
  },
  methods: {
    toggleFullText() {
      this.showFullText = !this.showFullText
    },
    applySimilarityMatch(text) {
      let annotatedText = text
      if (this.similarityMatch) {
        if (this.similarityMatch.detected_disinformation_parts) {
          const disinformationParts = this.similarityMatch
            .detected_disinformation_parts
          let parts = 0
          let shift = 0
          disinformationParts.forEach((part) => {
            let start = part.start_pos
            let end = part.end_pos + 2 // Python to Javascript work-around
            if (parts > 0) {
              start = start + shift
              end = end + shift
            }
            parts++
            const before = annotatedText
            annotatedText = this.markTextPart(
              before,
              start,
              end,
              this.similarityMatchExplanation
            )
            shift = shift + (annotatedText.length - before.length)
          })
        }
      }
      return annotatedText
    },
    markTextPart(text, start, end, disinfo) {
      const matchedDisinfo = text.substring(start, end)
      let tooltipElement =
        '<span onclick="inlineScriptShowDisinformationTooltip()" class="custom-tooltip">' +
        '<span style="text-decoration: underline">' +
        matchedDisinfo +
        '</span>' +
        '<span id="disinformation-tooltip" class="tooltip-text mt-2" style="text-decoration: none">' +
        disinfo +
        '</span>' +
        '</span>'
      return text.substr(0, start) + tooltipElement + text.substr(start + end)
    },
  },
}
</script>

<style scoped></style>
