<template>
  <v-container
    :class="[$style.mapContainer, small ? $style.smallMapContainer : null]"
    class="d-flex flex-column justify-center align-stretch"
  >
    <div
      id="map"
      ref="map"
      :class="[$style.map, small ? $style.smallMap : null]"
    ></div>
  </v-container>
</template>
<script>
import * as mapbox from 'mapbox-gl'
import utils from '../../../core/utils'
import internationalisationMixin from '../../../mixins/internationalisationMixin'
import { mapActions, mapState } from 'vuex'
import { CAMPAIGNS_ROUTE } from '../router'

export default {
  name: 'DashboardMap',
  mixins: [internationalisationMixin],

  props: {
    small: {
      type: Boolean,
      default: false,
    },
    origins: {
      type: Array,
    },
  },
  data() {
    return {
      viewState: {
        zoom: 0,
        pitch: 0,
        bearing: 0,
        center: [-1.5, 2],
      },
    }
  },
  created() {
    this.map = null
  },

  mounted() {
    if (!mapbox.supported()) {
      alert('Your browser does not support Mapbox GL')
    } else {
      this.map = new mapbox.Map({
        accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
        container: this.$refs.map,
        style: process.env.VUE_APP_MAPBOX_STYLE_URL,
        zoom: this.viewState.zoom,
        pitch: this.viewState.pitch,
        bearing: this.viewState.bearing,
        center: this.viewState.center,
      })
      this.buildMap()
    }
  },
  watch: {
    origins: function(newVal) {
      this.setCountries(newVal)
    },
  },
  computed: {
    campaignId() {
      return this.$route.params.id
    },
    isCampaignsView() {
      return this.$route && this.$route.name === CAMPAIGNS_ROUTE.name
    },
    ...mapState({
      narrativesQuery: (state) => state.dashboard.narratives.narrativesQuery,
      campaignsQuery: (state) => state.dashboard.campaigns.campaignsQuery,
    }),
  },
  methods: {
    async fetchArticleCount(country) {
      let query = this.isCampaignsView
        ? utils.deepCopy(this.campaignsQuery)
        : utils.deepCopy(this.narrativesQuery)
      query.country = [country]
      return this.isCampaignsView
        ? await this.fetchCampaignsSummaryData(query)
        : await this.fetchNarrativesSummaryData(query)
    },

    async setCountries(countries) {
      const layers = this.map.getStyle().layers
      const result = await this.distributeOrigins(
        this.$route.params.id,
        countries
      )
      if (layers.find((layer) => layer['id'] === 'vector_countries')) {
        this.map.setFilter(
          'vector_countries',
          ['in', 'SUBUNIT'].concat(result.low)
        )
      }
      if (layers.find((layer) => layer['id'] === 'vector_countries_medium')) {
        this.map.setFilter(
          'vector_countries_medium',
          ['in', 'SUBUNIT'].concat(result.medium)
        )
      }
      if (layers.find((layer) => layer['id'] === 'vector_countries_high')) {
        this.map.setFilter(
          'vector_countries_high',
          ['in', 'SUBUNIT'].concat(result.high)
        )
      }
    },
    async distributeOrigins(campaignId, countries) {
      let countryData = {}
      let total = 0
      for (let i = 0; i < countries.length; i++) {
        let country = countries[i]
        let data = await this.fetchArticleCount(country)
        countryData[country] = data.articleCount
        total += data.articleCount
      }
      let low = []
      let medium = []
      let high = []
      for (const countryDataKey in countryData) {
        if (countryData[countryDataKey] / total >= 0.5) {
          high.push(countryDataKey)
        } else if (countryData[countryDataKey] / total >= 0.2) {
          medium.push(countryDataKey)
        } else {
          low.push(countryDataKey)
        }
      }
      return {
        low,
        medium,
        high,
      }
    },
    buildMap() {
      this.map.on('load', () => {
        const layers = this.map.getStyle().layers
        let firstSymbolId
        for (let i = 0; i < layers.length; i++) {
          if (layers[i].type === 'symbol') {
            firstSymbolId = layers[i].id
            break
          }
        }
        this.map.addLayer(
          {
            id: 'vector_countries',
            source: {
              type: 'vector',
              url: 'mapbox://unchecked.2v04o1x5',
            },
            'source-layer': 'ne_10m_admin_0_countries-bqcg5t',
            type: 'fill',
            paint: {
              'fill-color': '#000',
              'fill-outline-color': '#000',
            },
          },
          firstSymbolId
        )

        this.map.addLayer(
          {
            id: 'vector_countries_medium',
            source: {
              type: 'vector',
              url: 'mapbox://unchecked.2v04o1x5',
            },
            'source-layer': 'ne_10m_admin_0_countries-bqcg5t',
            type: 'fill',
            paint: {
              'fill-color': '#0D3182',
              'fill-outline-color': '#0D3182',
            },
          },
          'vector_countries'
        )
        this.map.addLayer(
          {
            id: 'vector_countries_high',
            source: {
              type: 'vector',
              url: 'mapbox://unchecked.2v04o1x5',
            },
            'source-layer': 'ne_10m_admin_0_countries-bqcg5t',
            type: 'fill',
            paint: {
              'fill-color': '#FF0000',
              'fill-outline-color': '#FF0000',
            },
          },
          'vector_countries_medium'
        )

        this.map.setFilter('vector_countries', ['in', 'SUBUNIT'].concat([]))
        this.map.setFilter(
          'vector_countries_high',
          ['in', 'SUBUNIT'].concat([])
        )
        this.map.setFilter(
          'vector_countries_medium',
          ['in', 'SUBUNIT'].concat([])
        )

        this.setCountries(this.origins)
        this.map.on('click', 'vector_countries', (mapElement) => {
          const subunit = mapElement.features[0].properties.SUBUNIT
          this.addPopUpToMap(mapElement, subunit)
        })
        this.map.on('click', 'vector_countries_high', (mapElement) => {
          const subunit = mapElement.features[0].properties.SUBUNIT
          this.addPopUpToMap(mapElement, subunit)
        })
        this.map.on('click', 'vector_countries_medium', (mapElement) => {
          const subunit = mapElement.features[0].properties.SUBUNIT
          this.addPopUpToMap(mapElement, subunit)
        })
      })
    },
    addPopUpToMap(mapElement, country) {
      this.fetchArticleCount(country).then((response) => {
        const html = `
                  <ul>
                    <li><h2>${country}</h2></li>
                    <li> <strong>Article matches:</strong> ${this.formatNumber(
                      response.articleCount
                    )}</li>
                  </ul>
              `
        new mapbox.Popup()
          .setLngLat(mapElement.lngLat)
          .setHTML(html)
          .addTo(this.map)
      })
    },
    ...mapActions('dashboard/narratives', ['fetchNarrativesSummaryData']),
    ...mapActions('dashboard/campaigns', ['fetchCampaignsSummaryData']),
  },
}
</script>

<style lang="scss" module>
.mapContainer {
  position: absolute;
  top: 40px;
  bottom: 0;
  width: 69%;
}

.map {
  position: absolute;
  top: 30px;
  bottom: 0;
  width: inherit;
}

.smallMapContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 98%;
}

.smallMap {
  position: relative;
  top: 0;
  width: inherit;
  height: 75vh;
}
</style>
