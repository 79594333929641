<template>
  <v-layout column class="ma-2">
    <v-container
      id="scroll-target"
      ref="articleContainer"
      style="max-height: 400px"
      class="overflow-y-auto"
    >
      <v-container v-scroll:#scroll-target="onScrollEnd">
        <DashboardArticleListItem
          class="ma-2"
          v-for="article in articles"
          :key="article.id"
          v-bind:id="article.id"
          v-bind="article"
        />
      </v-container>
    </v-container>
  </v-layout>
</template>

<script>
import DashboardArticleListItem from './DashboardArticleListItem.vue'
import { mapState } from 'vuex'

export default {
  name: 'DashboardArticleList',
  components: {
    DashboardArticleListItem,
  },
  props: {
    campaignId: {
      type: [Number, String],
      required: true,
    },
  },

  data: () => ({
    term: '',
    offsetTop: 0,
  }),

  computed: mapState({
    articles: (state) => state.dashboard.campaign.articles,
    loadingArticles: (state) => state.dashboard.campaign.loadingArticles,
  }),
  methods: {
    onScrollEnd(e) {
      if (
        e.target.scrollTop ===
        e.target.scrollHeight - e.target.clientHeight
      ) {
        if (!this.loadingArticles) {
          this.$store.dispatch('dashboard/campaign/fetchNextArticles')
        }
      }
    },
    resetListScroll() {
      if (this.$refs.articleContainer) {
        this.$refs.articleContainer.scrollTop = 0
      }
    },
  },
}
</script>
