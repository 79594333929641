<template>
  <v-container fluid v-if="article">
    <v-layout column>
      <v-row class="mt-3 mb-5">
        <v-row class="pa-0">
          <v-col cols="1"></v-col>
          <v-col cols="11" class="pb-0 pt-4">
            <v-flex row class="ml-2 mb-0 pb-0">
              <v-btn color="secondary" icon @click="goBack">
                <v-icon>arrow_back</v-icon>
              </v-btn>

              <h2> {{ title }}</h2>
            </v-flex>
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10">
          <v-card outlined :loading="loadingArticle">
            <v-toolbar dark>
              <v-toolbar-title>
                Details
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-switch
                class="mt-5"
                v-model="showOriginal"
                color="primary"
                label="Show original language"
              >
                <template v-slot:label>
                  <div
                    ><strong class="white--text"
                      >Show original language</strong
                    ></div
                  >
                </template>
              </v-switch>
            </v-toolbar>
            <v-row>
              <v-col cols="12"></v-col>
            </v-row>
            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="5">
                <v-container class="pa-2">
                  <v-row>
                    <v-col cols="4">
                      Source
                    </v-col>
                    <v-col cols="8">
                      <v-btn
                        color="secondary"
                        elevation="2"
                        class="white--text"
                        :href="article.url"
                        target="_blank"
                        >Open
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      Domain
                    </v-col>
                    <v-col cols="8">
                      <a v-bind:href="domainUrl" target="_blank">
                        {{ article.domain }}
                      </a>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      Date
                    </v-col>
                    <v-col cols="8">
                      {{ formatDateTime(article.date) }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      Disinfo Match
                    </v-col>
                    <v-col cols="8">
                      Very High
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      Language
                    </v-col>
                    <v-col cols="8">
                      {{ article.language }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      Origin
                    </v-col>
                    <v-col cols="8">
                      {{ article.source_country }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col>
                          <strong>Give feedback</strong>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                elevation="2"
                                :class="articleFeedback === 1 ? '' : 'gray'"
                                v-on="on"
                                :color="articleFeedback === 1 ? 'red' : 'gray'"
                                v-on:click="submitFeedback(1)"
                              >
                                Disinformation
                              </v-btn>
                            </template>
                            <span
                              >Confirm that this article is disinformation</span
                            >
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                elevation="2"
                                :class="articleFeedback === 0 ? '' : 'gray'"
                                v-on="on"
                                :color="
                                  articleFeedback === 0 ? 'green' : 'gray'
                                "
                                v-on:click="submitFeedback(0)"
                              >
                                Not Disinformation
                              </v-btn>
                            </template>
                            <span
                              >Report that this article is not
                              disinformation</span
                            >
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="article.campaign_title" class="mt-8">
                    <v-container class="pa-2">
                      <v-row>
                        <v-col cols="4">
                          Campaign
                        </v-col>
                        <v-col cols="8" class="font-weight-bold">
                          <router-link
                            :to="campaignRoute"
                            style="text-decoration: none; color: #000;"
                          >
                            {{ article.campaign_title }}
                          </router-link>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          Keywords
                        </v-col>
                        <v-col cols="8">
                          {{ formatKeywords(article.campaign_keywords) }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="5">
                <DashboardArticleImpact
                  v-if="!loadingArticle && article.id"
                  :articleId="article.id"
                />
                <DashboardArticleChart
                  v-if="article"
                  :articleId="$route.params.id"
                />
              </v-col>
              <v-col cols="1"></v-col>
            </v-row>
            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="10">
                <v-card outlined tile>
                  <v-toolbar dark>
                    <v-icon class="mr-2" @click="toggleBacklinks"
                      >{{ showBacklinks ? 'remove' : 'add' }}
                    </v-icon>
                    <v-toolbar-title>Backlinks</v-toolbar-title>
                  </v-toolbar>
                  <v-container v-show="showBacklinks">
                    <v-data-table
                      :headers="headers"
                      :items="backLinking.comparisons"
                      class="elevation-1"
                      :loading="loadingArticleBackLinking"
                      :items-per-page="10"
                      no-data-text="No backlinks found."
                    >
                      <template v-slot:item.url="{ item }">
                        <a rel="nofollow" :href="item.url" target="_blank">{{
                          item.url
                        }}</a>
                      </template>
                      <template v-slot:item.distance="{ item }">
                        <span
                          >{{ Number(item.distance.toFixed(3)) * 100 }}%</span
                        >
                      </template>
                    </v-data-table>
                    <div
                      v-if="
                        backLinking &&
                          backLinking.comparisons &&
                          backLinking.comparisons.length > 0
                      "
                      class="my-3"
                    >
                      <v-btn
                        elevation="2"
                        class="mr-1"
                        dark
                        v-clipboard="() => copyDuplicateBacklinks()"
                        v-clipboard:error="clipboardErrorHandler"
                      >
                        Copy duplicates
                      </v-btn>
                      <v-btn
                        elevation="2"
                        class="mx-1"
                        dark
                        v-clipboard="() => copyOtherBacklinks()"
                        v-clipboard:error="clipboardErrorHandler"
                      >
                        Copy others
                      </v-btn>
                    </div>
                  </v-container>
                </v-card>
              </v-col>

              <v-col cols="1"></v-col>
            </v-row>

            <v-row class="">
              <v-col cols="1"></v-col>
              <v-col cols="10">
                <DashboardArticleTextContainer
                  v-bind:show-original="showOriginal"
                  v-bind:article="article"
                  v-bind:is-loading-similarity-match="
                    loadingArticleSimilarityMatch
                  "
                  v-bind:similarity-match="similarityMatch"
                />
              </v-col>
              <v-col cols="1"></v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import DashboardArticleImpact from '../components/Article/DashboardArticleImpact'
import DashboardArticleChart from '../components/Article/DashboardArticleChart'
import { mapState } from 'vuex'
import { CAMPAIGN_ROUTE } from '../router'
import DashboardArticleTextContainer from '../components/Article/DashboardArticleTextContainer'
import internationalisationMixin from '../../../mixins/internationalisationMixin'
import { map, without } from 'lodash'

export default {
  name: 'DashboardArticle',
  mixins: [internationalisationMixin],
  components: {
    DashboardArticleTextContainer,
    DashboardArticleImpact,
    DashboardArticleChart,
  },
  data: () => ({
    dialog: false,
    showOriginal: false,
    showFullText: false,
    showBacklinks: false,
    headers: [
      {
        text: 'Link',
        align: 'start',
        sortable: false,
        value: 'url',
      },
      { text: 'Duplication rate', value: 'distance' },
    ],
  }),
  created() {
    this.$store.dispatch('dashboard/fetchArticle', { ...this.$route.params })
  },
  methods: {
    toggleBacklinks() {
      this.showBacklinks = !this.showBacklinks
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    submitFeedback(value) {
      if (this.articleFeedback !== value) {
        this.$store
          .dispatch('dashboard/annotateArticle', {
            article_id: this.article.id,
            feedback: value,
          })
          .then(() =>
            this.$store.dispatch('dashboard/fetchArticleAnnotation', {
              ...this.$route.params,
            })
          )
      }
    },
    formatKeywords(keywords) {
      return keywords.join(', ')
    },
    copyDuplicateBacklinks() {
      let dupes = map(this.backLinking.comparisons, function(o) {
        if (o.is_duplicate) return o.url
      })
      dupes = without(dupes, undefined)
      return dupes.join('\n')
    },
    copyOtherBacklinks() {
      let not_dupes = map(this.backLinking.comparisons, function(o) {
        if (!o.is_duplicate) return o.url
      })
      not_dupes = without(not_dupes, undefined)
      return not_dupes.join('\n')
    },

    clipboardErrorHandler({ value }) {
      console.log('error', value)
    },
  },
  computed: {
    ...mapState({
      article: (state) => state.dashboard.article,
      backLinking: (state) => state.dashboard.backLinking,
      loadingArticle: (state) => state.dashboard.loadingArticle,
      loadingArticleBackLinking: (state) =>
        state.dashboard.loadingArticleBackLinking,
      articleFeedback: (state) => state.dashboard.articleFeedback,
      articleFeedbackDate: (state) => state.dashboard.articleFeedbackDate,
      similarityMatch: (state) => state.dashboard.similarityMatch,
      loadingArticleSimilarityMatch: (state) =>
        state.dashboard.loadingArticleSimilarityMatch,
    }),
    domainUrl: function() {
      return 'http://' + this.article.domain
    },
    title: function() {
      if (this.showOriginal) {
        return this.article.title
      }
      return this.article.translated_title
    },
    campaignRoute: function() {
      return {
        name: CAMPAIGN_ROUTE.name,
        params: { id: this.article.campaign_id },
      }
    },
  },
}
</script>
