<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-card outlined tile :loading="loadingNarratives">
          <v-card>
            <v-toolbar dark>
              <v-toolbar-title>Disinformation articles</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-spacer />
              <v-switch v-model="sortByImpact">
                <template v-slot:prepend>
                  <div
                    class="my-1"
                    style="cursor: pointer;"
                    @click="setSortByImpact(false)"
                  >
                    Latest
                  </div>
                </template>
                <template v-slot:append>
                  <div
                    class="my-1"
                    style="cursor: pointer;"
                    @click="setSortByImpact(true)"
                  >
                    Impact
                  </div>
                </template>
              </v-switch>
            </v-toolbar>
            <v-card-text>
              <DashboardNarrativeFilter
                v-bind:origins="allOrigins"
                v-bind:languages="allLanguages"
              />
            </v-card-text>
          </v-card>
          <DashboardNarrativeList v-bind:narratives="narratives" />
        </v-card>
      </v-col>
      <v-col cols="5">
        <DashboardMap v-if="origins.length > 0" v-bind:origins="origins" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import DashboardMap from '../components/DashboardMap'
import DashboardNarrativeList from '../components/Narratives/DashboardNarrativeList'
import DashboardNarrativeFilter from '../components/Narratives/Filter/DashboardNarrativeFilter'
import { DEFAULT_NARRATIVE_QUERY } from '../../../store/modules/dashboard.store'
import internationalisationMixin from '../../../mixins/internationalisationMixin'

export default {
  name: 'DashboardNarratives',
  mixins: [internationalisationMixin],
  components: {
    DashboardMap,
    DashboardNarrativeList,
    DashboardNarrativeFilter,
  },

  data() {
    return {
      sortByImpact: false,
    }
  },
  created() {
    if (this.analystLanguage) {
      this.selectedLanguage = this.analystLanguage
    }
    let narrativeQuery = { ...DEFAULT_NARRATIVE_QUERY }
    narrativeQuery.language = []
    if (this.selectedLanguage) {
      narrativeQuery.language.push(this.selectedLanguage)
    }
    if (this.term) {
      narrativeQuery.term = this.term
    }
    this.$store.dispatch(
      'dashboard/narratives/applyNarrativeFilter',
      narrativeQuery
    )
    this.$store.dispatch('dashboard/narratives/fetchTotalNarrativesSummary')
  },
  watch: {
    sortByImpact(newValue) {
      this.$store.dispatch('cancelPendingRequests').then(() => {
        this.$store.dispatch('dashboard/narratives/clearNarratives')
        this.$store
          .dispatch('dashboard/narratives/fetchNarratives', newValue)
          .then(() =>
            this.$store.dispatch('dashboard/narratives/fetchNarrativesSummary')
          )
      })
    },
  },
  methods: {
    setSortByImpact(value) {
      this.sortByImpact = value
    },
  },

  computed: mapState({
    narratives: (state) => state.dashboard.narratives.narratives,
    origins: (state) => state.dashboard.narratives.origins,
    languages: (state) => state.dashboard.narratives.languages,
    allLanguages: (state) => state.dashboard.narratives.allLanguages,
    allOrigins: (state) => state.dashboard.narratives.allOrigins,
    loadingNarratives: (state) => state.dashboard.narratives.loadingNarratives,
    analystLanguage: (state) => state.dashboard.narratives.analystLanguage,
    narrativesCount: (state) => state.dashboard.narratives.narrativesCount,
  }),
}
</script>

<style lang="scss" module></style>
